import { React, useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import LeadIndexTable from "./LeadIndexTable";

const LeadIndex = () => {
  const { t } = useTranslation();
  let { status } = useParams();


  return (
    <>
      <div className="d-sm-flex justify-content-between">
        {/* <div className="relative-h">
          {Cookies.get("permissions").indexOf("employee-create") !== -1 ? (
            <NavLink
              to="/hr/customer/create"
              className="btn btn-icon btn-primary"
            >
              {t("Add Employee")}
            </NavLink>
          ) : (
            <></>
          )}
          <div className=" d-inline-block pull-right">
            {Cookies.get("permissions").indexOf("Import") !== -1 ? (
              <>
                <button
                  className="btn btn-outline-dark me-2"
                  data-bs-toggle="modal"
                  data-bs-target="#employeeimportModal"
                >
                  {t("Import XLSX")}
                </button>
                <button
                  className="btn btn-outline-dark mx-3 d-none"
                  id="employeeoutputModal-show-button"
                  data-bs-toggle="modal"
                  data-bs-target="#employeeoutputModal"
                >
                  {t("Output XLSX")}
                </button>
              </>
            ) : (
              <></>
            )}
            
          </div>
        </div> */}
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card mb-4">
            <div className="card-header pb-0">
              <h6>{t("All Leads")}</h6>
            </div>
            <div className="card-body px-0 pt-0 pb-2">
              <LeadIndexTable/>
            </div>
          </div>
        </div>
      </div>

      {/* <ImportModal/> */}
    </>
  );
}

export default LeadIndex