import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import dateFormat from "dateformat";
import flatpickr from "flatpickr";
import callFetch from "helpers/callFetch";
import { NumericFormat } from 'react-number-format';
import { Company } from "context/DashboardContext/DashboardContext";
import { useAlert } from "context/AlertContext/AlertContext";
import Alert from "helpers/Alert/Alert";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EmailSend from "examples/EmailSend/EmailSend";
const InvoiceEdit = () => {
  const {setColor,setIcon,setTitle,setMessage,setIsShow,isShow} = useAlert();
  const { dashboardlogo } = Company();
  const params = useParams();
  const {} = useAlert();
  const { t } = useTranslation();
  const [customerId, setCustomerId] = useState(0);
  const [currency, setCurrency] = useState('EUR');
  const [data, setData] = useState([]);
  const [emails, setEmails] = useState([{ name: '', email: '', currentEmail: '' }]);
  const [items, setItems] = useState([{ id: 0, title: '',product:'', quantity: 1, price: 0, currentPrice: 0, total: 0 }]);
  const [itemSummery, setItemSummery] = useState({ items: [], emails: [], subTotal: 0, discountPercentage: 0, discount: 0, taxPercentage: 19, tax: 0, total: 0 });
  const [saving, setSaving] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [refresh2, setRefresh2] = useState(0);
  const [refresh3, setRefresh3] = useState(0);
  const [invoice,setInvoice] = useState({});
  const [invoicPdf, setInvoicePdf] = useState('');
  const [pdfgenerate, setPDFgenerate] = useState(0);
  const [clientId, setClientId] = useState(0);
  const {
      register,
      handleSubmit,
      setError,
      setValue,
      formState: { errors },
  } = useForm();
  useEffect(() => {
      var subTotal = 0;
      items.map((item) => {
          subTotal += item.total;
      });
      itemSummery.subTotal = subTotal;
      itemSummery.tax = parseFloat((subTotal * (itemSummery.taxPercentage / 100)).toFixed(2));
      itemSummery.discount = parseFloat((subTotal * (itemSummery.discountPercentage / 100)).toFixed(2));
      itemSummery.total = subTotal - itemSummery.discount + itemSummery.tax;
      itemSummery.items = items;
      setItemSummery(itemSummery);
      setRefresh2(refresh2 + 1);
  }, [refresh]);

  useEffect(() => { }, [refresh2]);

useEffect(() => {
    flatpickr(".flat-pickr");
    callFetch("invoices/create?company="+dashboardlogo, "GET", []).then((res) => {
        setData(res?.data);
        setRefresh3(refresh3 + 1);
    });
}, [0]);

useEffect(() => {
      if (params?.id > 1 && refresh3 !== 0){
        callFetch("invoices/"+params?.id+"/edit?company="+`${dashboardlogo}`, "GET", []).then((res) => {
            setInvoice(res.data)
            setValue('quotation_orders',res.data?.product_id)
            setValue('description',res.data?.description)
            setCurrency(res.data?.currency)
            for (let [key, value] of Object.entries(res.data)) {
              setValue(key, (value == null || value == 'null' ? "" : value ));
              }
            const product_details = JSON.parse(res.data?.product_details)
            setItemSummery(product_details);
            setItems(product_details.items);
            setValue('invoice_number',res.data?.invoice_number)
            setPDFgenerate(pdfgenerate +1);
        });
      }
}, [params.id,refresh3]);

useEffect(() => {
  if (customerId < 1)
      return;
  callFetch("invoices/"+customerId+`?company=${dashboardlogo}&invoiceId=${params?.id}`, "GET", []).then((res) => {
    if(res?.customer){
        if(res?.customer?.invoice !== null){
            setValue('description',res?.customer?.invoice?.description)
            const product_details = JSON.parse(res?.customer?.invoice?.product_details)
            setItemSummery(product_details);
            setItems(product_details?.items);
        }else{
            setValue('description','')
            setItemSummery({ items: [], emails: [], subTotal: 0, discountPercentage: 0, discount: 0, taxPercentage: 19, tax: 0, total: 0 });
            setItems([{ id: 0, title: '',product:'', quantity: 1, price: 0, currentPrice: 0, total: 0 }]);
        }
        
    }
    if(res?.data !== null){
        setValue('description',res.data?.description)
        const product_details = JSON.parse(res.data?.product_details)
        setItemSummery(product_details);
        setItems(product_details?.items);
    }
    setRefresh2(refresh2 + 1);
  });
}, [customerId]);


  function deleteProduct() {
      var titems = [];
      items.map((t) => {
          if (!t)
              return;
          titems.push(t);
      });
      setItems(titems);
      setRefresh(refresh + 1);
  }


  useEffect(()=>{
    if(pdfgenerate != 0){
        callFetch('invoices/pdf/'+params.id+`?company=${dashboardlogo}`, "GET",[]).then((res) =>{
            if(res.message === 'success'){
                setInvoicePdf(res?.url);
            }
        });
    }
  },[pdfgenerate])

  const [menu, setMenu] = useState(null);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => {
      window.open(invoicPdf,"_blank");
      setMenu(null)
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Download</MenuItem>
    </Menu>
  ); 



  const onSubmit = (formData) => {
      setSaving(true);
      formData.total = itemSummery.total;
      formData.company = dashboardlogo
      formData.product_details = JSON.stringify(itemSummery);
      callFetch("invoices/"+ params?.id, "POST", formData, setError).then((res) => {
          setSaving(false);
          if (!res.ok) return;
          setPDFgenerate(pdfgenerate + 1)
          setMessage("Invoice Updated successfully");
          setColor("success");
          setTitle("Success")
          setIcon("check");
          setIsShow(!isShow);
          setRefresh3(refresh3 + 1);
          setSubmitSuccess(true);
      });
  };

return<div className="row">
  <div className="col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9">
      <div className="card mb-4">
          <div className="card-header pb-0">
              <h6>{t('Edit Invoice')}</h6>
          </div>
          <div className="card-body">
              <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                  <input type="hidden" defaultValue="PUT" {...register("_method")} />
                  <div className="row g-3">
                      <div className="col-md-3">
                          <label>{t('Invoice Number')} *</label>
                          <input
                              type="text"
                              className="form-control"
                              placeholder={t('eg: 300001')}
                              {...register("invoice_number", {
                                  required: true,
                              })}
                              readOnly
                              required
                          />
                          <div className="invalid-feedback">{errors.order_number && errors.order_number.message}</div>
                      </div>

                      <div className="col-md-3">
                          <div className="form-group">
                              <label>
                                  {t('Quotation/Orders')} *
                              </label>
                              <br />
                              <select
                                  className="form-control"
                                  {...register("quotation_orders", {
                                      required: true,
                                  })}
                                  onChange={(e) => {
                                      let customerId = e.target.value;
                                      setCustomerId(customerId);
                                  }}
                                  required
                              >
                                    <option value="">--</option>
                                    {data?.quotation && data?.quotation.map((quotation) => (
                                        <option key={quotation.id} value={quotation.quotation_number}>{quotation.quotation_number+" - "+quotation.client?.company_name}</option>
                                    ))}
                                    {data?.order && data?.order.map((order) => (
                                        <option key={order.id} value={order.order_number}>{order.order_number+" - "+order?.quotation?.client?.company_name}</option>
                                    ))}
                                    {data?.customers && data?.customers?.map((customer) => (
                                        <option key={customer.id} value={customer.client_number}>{customer.client_number+" - "+customer?.company_name}</option>
                                    ))}
                              </select>
                              <div className="invalid-feedback">{errors.quotation_orders && errors.quotation_orders.message}</div>
                          </div>
                      </div>

                      <div className="col-md-3">
                          <div className="form-group">
                              <label>
                                  {t('Currency')} *
                              </label>
                              <br />
                              <select
                                  className="form-control"
                                  {...register("currency", {
                                      required: true,
                                  })}
                                  required
                                onChange={(e)=>setCurrency(e.target.value)}
                              >
                                  <option value="EUR">EUR (€)</option>
                                  <option value="USD">USD ($)</option>
                              </select>
                              <div className="invalid-feedback">{errors.currency && errors.currency.message}</div>
                          </div>
                      </div>

                      <div className="col-md-3">
                          <label>
                              {t('Invoice Date')} *
                          </label>
                          <input
                              type="date"
                              className="form-control mb-4 flat-pickr"
                              placeholder={t('eg. 2001-03-20')}
                              defaultValue={dateFormat(new Date(), "yyyy-mm-dd")}
                              {...register("invoice_date", {
                                  required: true,
                              })}
                              required
                          />
                          <div className="invalid-feedback">{errors.start_date && errors.start_date.message}</div>
                      </div>
                  </div>


                        <div className="row mt-3">
                            <div className="col-md-6">
                                <label>{t('GIB Invoice Nr')} *</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={t('gib: 300001')}
                                    {...register("gib_invoice_nr", {
                                        required: true,
                                    })}
                                    required
                                />
                                <div className="invalid-feedback">{errors.gib_invoice_nr && errors.gib_invoice_nr.message}</div>
                            </div>
                            <div className="col-md-6">
                                <label>{t('Invoice Paid')} *</label>
                                <select
                                    className="form-control"
                                    {...register("invoice_status", {
                                        required: true,
                                    })}
                                    required
                                >
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                                <div className="invalid-feedback">{errors.invoice_status && errors.invoice_status.message}</div>
                            </div>
                          </div>

                  <div className="row mt-3">
                      <div className="col-md-12">
                          <label>{t('Description')}</label>
                          <textarea className="form-control" rows="3"
                              placeholder="eg. Description"
                              {...register("description")}></textarea>
                      </div>
                  </div>

                  <div className="mt-3">
                      {items && items.map((item, i) => (
                          <div key={i}>
                              {i > 0 && <hr />}
                              <div className="row mt-2">
                                  <div className="col-md-8">
                                      <label>{t('Product')}</label>
                                      <input type="text" className="form-control" placeholder={t('Select Product')} defaultValue={items[i].product} onChange={(e) => {
                                            items[i].id = i + 1;
                                            items[i].product = e.target.value;
                                            setItems(items);
                                            setRefresh(refresh + 1);
                                        }}/>
                                  </div>
                                  <div className="col-md-2">
                                      <label>{t('Qty/Hrs')}</label>
                                      <input type="number" className="form-control" placeholder="eg. 0.00" value={items[i]?.quantity} onChange={(e) => {
                                          let value = 0;
                                          if (e.target.value.length && !isNaN(e.target.value))
                                              value = e.target.value;
                                          items[i].quantity = parseFloat(value);
                                          items[i].total = items[i].currentPrice * items[i].quantity;
                                          setItems(items);
                                          setRefresh(refresh + 1);
                                      }} />
                                  </div>
                                  <div className="col-md-2">
                                      <label>{t('Amount')}</label>
                                      <input type="number" className="form-control d-inline" style={{ width: '84%' }} placeholder="eg. 0.00" value={items[i].currentPrice ? items[i].currentPrice : ''} onChange={(e) => {
                                              let value = 0;
                                              if (e.target.value.length && !isNaN(e.target.value))
                                                  value = e.target.value;
                                              items[i].currentPrice = parseFloat(value);
                                              items[i].total = items[i].currentPrice * items[i].quantity;
                                              setItems(items);
                                              setRefresh(refresh + 1);
                                      }} />
                                      &nbsp;
                                      <i className="fa-solid fa-circle-xmark text-danger" data-key={i} onClick={() => { delete items[i]; deleteProduct(); }}></i>
                                  </div>
                              </div>
                          </div>
                      ))}

                      <div className="row mt-3">
                          <div className="col-md-12">
                              <p className="btn btn-dark" style={{ cursor: 'pointer' }} onClick={() => { setItems([...items, { id:0, title: '',product:'', quantity: 1, price: 0, currentPrice: 0, total: 0 }]); setRefresh(refresh + 1); }}><i className="fa-solid fa-circle-plus"></i>&nbsp;{t('Add Item')}</p>
                          </div>
                      </div>

                      <hr />

                      <div className="row">
                <div className="col-md-6 offset-md-6 border">
                    <div className="row">
                        <div className="col-md-7 border-end">
                            <label className="d-block text-end">{t('Sub Total')}</label>
                            {/* <select {...register('amountPerchantage')} onChange={(e) =>{ itemSummery.amountPerchantage = parseFloat(e.target.value); setItemSummery(itemSummery);
                                setRefresh(refresh + 1) }} className="form-control mb-2" style={{width: '70%', position: 'relative', left: '68px'}}>
                                <option value="0">--</option>
                                <option value="100">100%</option>
                                <option value="70">70%</option>
                                <option value="30">30%</option>
                            </select> */}
                        </div>
                        <div className="col-md-5">
                            <p className="text-end">
                                <NumericFormat 
                                    value={itemSummery.subTotal}
                                    displayType="text" 
                                    thousandSeparator={"."} 
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                />
                                </p>
                        </div>
                    </div>
                    <div className="row border-top d-none">
                        <div className="col-md-3 border-end">
                            <label className="d-block text-end">{t('Discount')}</label>
                        </div>
                        <div className="col-md-3 border-end">
                            <input type="number" className="form-control" value={itemSummery.discountPercentage} onChange={(e) => {
                                let value = 0;
                                if (e.target.value.length && !isNaN(e.target.value))
                                    value = e.target.value;
                                itemSummery.discountPercentage = parseFloat(value);
                                setItemSummery(itemSummery);
                                setRefresh(refresh + 1);
                            }} />
                        </div>
                        <div className="col-md-1 border-end">
                            <p className="text-end">%</p>
                        </div>
                        <div className="col-md-5">
                            <p className="text-end">
                                <NumericFormat 
                                    value={itemSummery.subTotal}
                                    displayType="text" 
                                    thousandSeparator={"."} 
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                />
                            </p>
                        </div>
                    </div>
                    <div className="row border-top">
                        <div className="col-md-3 border-end">
                            <label className="d-block text-end">{t('Tax')}</label>
                        </div>
                        <div className="col-md-4 border-end">
                           <select {...register('tax')} className="form-control" value={itemSummery.taxPercentage} onChange={(e) => {
                                let value = 19;
                                if (e.target.value.length && !isNaN(e.target.value))
                                    value = e.target.value;
                                itemSummery.taxPercentage = parseFloat(value);
                                setItemSummery(itemSummery);
                                setRefresh(refresh + 1);
                            }}>
                                <option value="19">19%</option>
                                <option value="0">0%</option> 
                           </select>
                        </div>
                        <div className="col-md-5">
                            <p className="text-end">
                                <NumericFormat 
                                    value={itemSummery.tax}
                                    displayType="text" 
                                    thousandSeparator={"."} 
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                />
                            </p>
                        </div>
                    </div>
                    <div className="row bg-gray-200">
                        <div className="col-md-7">
                            <label className="d-block text-end">{t('Total')}</label>
                        </div>
                        <div className="col-md-5">
                            <p className="text-end">
                                <NumericFormat 
                                    value={itemSummery.total}
                                    displayType="text" 
                                    thousandSeparator={"."} 
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
                  </div>

                 

                  <div className="row mt-3">
                      <div className="col-md-12">
                          {!saving && (
                              <button type="submit" className="btn btn-primary">
                                  {t('Save')}
                              </button>
                          )}
                          {saving && (
                              <button type="submit" className="btn btn-disabled" disabled>
                                  {t('Saving ...')}
                              </button>
                          )}
                          {
                                invoicPdf && <>
                                                <button onClick={openMenu} type="button" className="btn btn-outline-primary ms-2">
                                                    {t('Download PDF')}&nbsp; <Icon>expand_more</Icon>
                                                </button>
                                                {renderMenu}
                                                </>

                          }
                      </div>
                  </div>
              </form>
          </div>
      </div>
  </div>
    <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
        <EmailSend title="Invoice" pdf={invoicPdf} data={invoice}/>
    </div>
  { submitSuccess && <Alert/>}
  </div>
          
      ;
}
export default InvoiceEdit