import callFetch from "helpers/callFetch";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from "react-router-dom";

const RoleCretae = () => {
    let displayName = '';
    let params = useParams();
    const { t } = useTranslation();
    const [resData, setResData] = useState(null);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
    };

    useEffect(() => {
        callFetch("roles/create", "GET", [], setError).then((res) => {
            setResData(res.data);
            setValue('role_for', 0);
        });
    }, [setError, setValue, params]);

    const onSubmit = (formData) => {
        setSaving(true);
        callFetch("roles/save", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to='/settings/roles' /> :
        <div className="row">
            <div className="col-12">
                <div className="card mb-4">
                    <div className="card-header pb-0">
                        <h6>{t('Create New Role')}</h6>
                    </div>
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label>
                                        {t('Role Name')} <b>*</b>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('Role Name')}
                                        {...register("name", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                </div>
                            </div>
                            <div className="row g-3">
                                    <label>{t('Permissions')}</label>

                                    <div className="form-check ms-2">
                                        <input className="form-check-input" id="selectAll" onChange={handleSelectAll} type="checkbox" checked={isCheckAll} />
                                        <label htmlFor="selectAll" className="mb-0">Select All</label>
                                    </div>
                                    
                                    <div className="table-responsive">
                                        <table className="table align-items-center mb-0">
                                            <thead>
                                                <tr>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{t('Title')}</th>
                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{t('Read')}</th>
                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{t('Create')}</th>
                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{t('Update')}</th>
                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{t('Delete')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {resData?.permissions ?
                                                    Object.keys(resData.permissions).map((permissionname, key) => (
                                                        <>
                                                        {permissionname != 'Dashboard' ? (
                                                            <tr key={'permissionname' + key}>
                                                                <td>{t(permissionname)}</td>
                                                                {resData.permissions[permissionname] && resData.permissions[permissionname].map((permission, permissionkey) => (
                                                                    <td className="text-center" key={'permissions' + permissionkey}>
                                                                        {/* <div className="form-check d-inline-block" style={{ paddingLeft: '0' }} data-optional={permission.display_name !== displayName ? displayName = permission.display_name : ''}>
                                                                            <input className="form-check-input mx-0" type="checkbox" value={permission.name} {...register("permission")} />
                                                                        </div> */}
                                                                        <div className="form-check" data-optional={permission.display_name !== displayName ? displayName = permission.display_name : ''}>
                                                                            <input defaultChecked={isCheckAll} className="form-check-input mx-0" type="checkbox" value={permission.name} {...register("permission")} />
                                                                        </div>
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        ) : <></>}
                                                        </>
                                                        
                                                    )) : <></>}
                                            </tbody>
                                        </table>
                                        <div className="mt-4">
                                           <p className="p-0 m-0 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{t('Dashboard Permissions')}</p>
                                        </div>
                                        <table class="table align-items-center mb-0 mt-0 pt-0">
                                            <thead>
                                                <tr>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
                                                </tr>
                                            </thead>
                                            {resData?.permissions?
                                                Object.keys(resData.permissions).map((permissionname, key) => (
                                                <>
                                                {permissionname == 'Dashboard' ? (
                                                    <tbody key={'permissionname'.key}>
                                                            <tr>
                                                            {resData.permissions[permissionname] && resData.permissions[permissionname].map((permission, permissionkey) => (
                                                                <td>
                                                                    <div className="form-check" data-optional={permission.display_name !== displayName ? displayName = permission.display_name : ''}>
                                                                        <input id={permission.display_name+permissionkey} className="form-check-input mx-0" type="checkbox" value={permission.name} {...register("permission")} />
                                                                        <label className=" text-capitalize" htmlFor={permission.display_name+permissionkey}>{permission.name}</label>
                                                                    </div>
                                                                </td>
                                                                ))}
                                                            </tr>
                                                    </tbody>
                                                ) : <></>}
                                                </>
                                            )) : <></> }
                                        </table>
                                </div>
                            </div>
                            <div className="col-12 my-4">
                                {!saving && (
                                    <button type="submit" className="btn btn-primary">
                                        {t('Save')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled" disabled>
                                        {t('Saving ...')}
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>;
}

export default RoleCretae