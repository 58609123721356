import { Cancel, CancelOutlined } from '@mui/icons-material';
import CropIcon from '@mui/icons-material/Crop';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Icon,
  Slider,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from './utils/cropImage';
import SoftButton from 'components/SoftButton';
import SoftBox from 'components/SoftBox';
import "./style.css"



const CropEasy = ({ photoURL, setOpenCrop,WithoutCrop, setPhotoURL, setFile, filePhoto }) => {
  const fixedWidth = 250; // Define your fixed width here
  const fixedHeight = 250; // Define your fixed height here
  const aspectRatioThreshold = 2.0; // Adjust this threshold as needed
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [aspectRatio, setAspectRatio] = useState(fixedWidth / fixedHeight);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    
  };


  useEffect(() => {
    const img = new Image();
    img.src = photoURL;
    img.onload = () => {
      const imageWidth = img.width;
      const imageHeight = img.height;
  
      // Calculate the aspect ratio based on the image size
      const calculatedAspectRatio = imageWidth / imageHeight;
  
      // Calculate the crop width and height based on the fixed dimensions
      let cropWidth = fixedWidth;
      let cropHeight = fixedHeight;
  
      // Check if the image is considered "big" based on the threshold
      const isBigImage = calculatedAspectRatio >= aspectRatioThreshold;
  
      if (isBigImage) {
        // If it's a big image, adjust crop width and height while maintaining aspect ratio
        if (calculatedAspectRatio > 1) {
          cropHeight = fixedWidth / calculatedAspectRatio;
        } else {
          cropWidth = fixedHeight * calculatedAspectRatio;
        }
      }
  
      // Set the aspect ratio and crop size
      setAspectRatio(cropWidth / cropHeight);
      //setCropSize({ width: cropWidth, height: cropHeight });
    };
  }, [photoURL]);


  const cropImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        photoURL,
        croppedAreaPixels,
        rotation,
      );
      setPhotoURL(croppedImage);
      setFile(croppedImage);
      setOpenCrop(false);
    } catch (error) {
      
    }

   
  };


return (
    <>
      <DialogContent
        dividers
        sx={{
          position: 'relative',
          height: 400,
          width: 'auto',
          minWidth: { sm: 500 },
        }}
      >
        <Cropper
          image={photoURL}
          crop={crop}
          zoom={zoom}
          cropShape="round"
          showGrid={false}
          rotation={rotation}
          aspect={aspectRatio}
          onZoomChange={setZoom}
          onRotationChange={setRotation}
          onCropChange={setCrop}
          onCropComplete={cropComplete}
        />
      </DialogContent>
      <DialogActions sx={{ flexDirection: 'column', mx: 3, my: 2 }}>
        <Box sx={{ width: '100%', mb: 1 }}>
          <Box>
            <Typography fontSize="0.825rem">Zoom: {zoomPercent(zoom)}</Typography>
            <Slider
              sx={{
                '& .MuiSlider-track': {
                  backgroundImage: 'none', // Remove background image
                  backgroundColor: 'transparent', // Set background color to transparent
                },
              }}
              valueLabelDisplay="auto"
              aria-labelledby="discrete-slider"
              valueLabelFormat={zoomPercent(zoom)}
              min={1}
              max={3}
              step={0.01}
              value={zoom}
              onChange={(e, zoom) => setZoom(zoom)}
            />
          </Box>
          <Box>
            <Typography fontSize="0.825rem">Rotation: {rotation + '°'}</Typography>
            <Slider
             sx={{
                '& .MuiSlider-track': {
                  backgroundImage: 'none', // Remove background image
                  backgroundColor: 'transparent', // Set background color to transparent
                },
              }}
              valueLabelDisplay="auto"
              min={0}
              max={360}
              value={rotation}
              onChange={(e, rotation) => setRotation(rotation)}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flexWrap: 'wrap',
          }}
        >
          {/* <Button
            variant="outlined"
            startIcon={<Cancel />}
            onClick={() => setOpenCrop(false)}
          >
            Cancel
          </Button>
          <></>
          <Button 
            variant="contained"
            startIcon={<CropIcon className='me-2' />}
            onClick={cropImage}
          >
            Crop
          </Button> */}
         <SoftBox display="flex" justifyContent="between" mb={0} ml={2}>
          <SoftBox mr={3}>
            <button type='button' onClick={() => setOpenCrop(false)} className="btn btn-icon btn-outline-danger jus">
              <CancelOutlined/>&nbsp;
              Cancel
            </button>
          </SoftBox>
          <SoftBox mr={3}>
            {/* <button type='button' onClick={() => WithoutCrop(false)} className="btn btn-icon btn-danger me-2">
              Without Crop
            </button> */}
            <button type='button' onClick={cropImage} className="btn btn-icon btn-primary" >
              <Icon>crop</Icon>&nbsp;
              Crop
            </button>
          </SoftBox>
          
        </SoftBox>
        </Box>
      </DialogActions>
    </>
  );
};

export default CropEasy;

const zoomPercent = (value) => {
  return `${Math.round(value * 100)}%`;
};
