import Cookies from "js-cookie";

const { createContext, useState, useContext, useEffect } = require("react");

const DashboardContext = createContext(null)

export function DashboardContextProvider({children}){
    const [dashboardlogo, setDashboardLogo] = useState(Cookies.get('company'));
    const [url, setUrl] = useState("solacloud")
    useEffect(()=>{
        if(!Cookies.get('user'))
            return;
        if(JSON.parse(Cookies.get('user')).id !== 1){
            if(Cookies.get('permissions').indexOf('solacloud') !== -1){
                Cookies.set('company','solacloud')
                setDashboardLogo('solacloud');
            }else if(Cookies.get('permissions').indexOf('qontrolo') !== -1){
                Cookies.set('company','qontrolo')
                setDashboardLogo('qontrolo');
            }else if(Cookies.get('permissions').indexOf('execute') !== -1){
                Cookies.set('company','execute')
                setDashboardLogo('execute');
            }else{
                setDashboardLogo("solacloud");
            }
        }else{
            Cookies.set('company',dashboardlogo)
            setDashboardLogo("solacloud");
        }

        if(dashboardlogo){
            setDashboardLogo(dashboardlogo);
            Cookies.set('company',dashboardlogo)
        }      
    },[dashboardlogo])
    return <>
        <DashboardContext.Provider value={{dashboardlogo,setDashboardLogo}}>
            {children}
        </DashboardContext.Provider>
    </>
}

export function Company(){
    const context = useContext(DashboardContext)
    return context;
}