import SoftSnackbar from 'components/SoftSnackbar';
import { useAlert } from 'context/AlertContext/AlertContext';
import { Company } from 'context/DashboardContext/DashboardContext';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const Alert = () => {
    const navigate = useNavigate();
    const {isShow,color,icon,title,message} = useAlert();
    const {dashboardlogo} = Company();
    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
          color={color}
          icon={icon}
          title={title}
          content={message}
          dateTime="Just Now"
          open={successSB}
          onClose={closeSuccessSB}
          close={closeSuccessSB}
          bgWhite
        />
      );
    useEffect(() => {
      openSuccessSB();
    },[isShow])

    useEffect(() => {
        if(!dashboardlogo){
            openSuccessSB();
            navigate('/dashboard')
        }
    },[0])
  return (
    <>
        {/* {renderSuccessSB} */}
    </>
  )
}

export default Alert