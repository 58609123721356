import { React, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import callFetch from "helpers/callFetch";
import { Company } from "context/DashboardContext/DashboardContext";
import { useAlert } from "context/AlertContext/AlertContext";
function Sidebar() {
    const {setColor,setIcon,setTitle,setMessage} = useAlert();
    const { dashboardlogo, setDashboardLogo } = Company()
    const router = useNavigate();
    const { t } = useTranslation();
    const [lastMessage, setLastMessage] = useState([]);
    const [authId, setAuthId] = useState('');
    const [nowDrop, setNowDrop] = useState(window.location.href.split('/')[3]);
    const [childDrop, setChildDrop] = useState(window.location.href.split('/')[4])
    let navClassName = "nav-link";
    let activeClassName = "nav-link active";
    let dropdownClass = "collapse";
    let dropdownClassShow = "collapse show";
    useEffect(() => {
        if(window.location.href.split('/')[4] === 'execute'){
            setDashboardLogo(window.location.href.split('/')[4]);
        }else if(window.location.href.split('/')[4] === 'qontrolo'){
            setDashboardLogo(window.location.href.split('/')[4]);
        }else if(window.location.href.split('/')[4] === 'solacloud'){
            setDashboardLogo(window.location.href.split('/')[4]);
        }
        if(dashboardlogo === null){
            setColor('warning')
            setIcon('warning')
            setTitle('Warning')
            setMessage('Please select a company')
            setNowDrop('dashboard');
        }
        if(window.location.href.split('/')[4] !== "lead-managment"){
            setChildDrop("")
        }else{
            setChildDrop(window.location.href.split('/')[4])
        }
    },[window.location.href.split('/')[4]])
    return (
        <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3" id="sidenav-main">
            <div className="sidenav-header d-flex align-items-center">
                <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav" />
                <a className="navbar-brand" style={{ maxWidth:"80%" }} href="/">
                    {   
                        dashboardlogo === 'execute' ? <img src={`/assets/img/execute.png`} className="navbar-brand-img h-100" alt="main_logo" />:
                        dashboardlogo === 'qontrolo' ? <img src={`/assets/img/logo.png`} className="navbar-brand-img h-100" alt="main_logo" />:
                        dashboardlogo === 'solacloud' ? <img src={`/assets/img/solacloud.png`} className="navbar-brand-img h-100" alt="main_logo" />:<></>
                        // <img src={`/assets/img/logo.png`} className="navbar-brand-img h-100" alt="main_logo" />
                    }
                </a>
            </div>
            <hr className="horizontal dark mt-0" />
            <div className="collapse navbar-collapse w-auto" id="sidenav-collapse-main">
                <ul className="navbar-nav">
                    <li className={"nav-item"}>
                            <a data-bs-toggle="collapse" href="#dashboard" className={nowDrop === 'dashboard' ? activeClassName : navClassName} aria-controls="dashboard" role="button" aria-expanded={nowDrop === 'dashboard'}>
                                <i className="fa-solid fa-dashboard icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                <span className="nav-link-text ms-1">{t('Dashboard')}</span>
                            </a>
                            <div className={nowDrop === 'dashboard' ? dropdownClassShow : dropdownClass} id="dashboard">
                                <ul className="nav ms-4 ps-3">
                                    {Cookies.get('permissions').indexOf("execute") !== -1 ? (
                                        <li className="nav-item">
                                            <NavLink to="/dashboard/execute" onClick={(e) => setNowDrop('dashboard')} className={ dashboardlogo === "execute" ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('Execute')}</span>
                                            </NavLink>
                                        </li>
                                    ) : <></>}

                                    {Cookies.get('permissions').indexOf("qontrolo") !== -1 ? (
                                        <li className="nav-item">
                                            <NavLink to="/dashboard/qontrolo" onClick={(e) => {setNowDrop('dashboard')}} className={dashboardlogo === "qontrolo" ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('Qontrolo')}</span>
                                            </NavLink>
                                        </li>
                                    ) : <></>}

                                    {Cookies.get('permissions').indexOf("solacloud") !== -1 ? (
                                        <li className="nav-item">
                                            <NavLink to="/dashboard/solacloud" onClick={(e) => setNowDrop('dashboard')} className={dashboardlogo === "solacloud" ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('Solacloud')}</span>
                                            </NavLink>
                                        </li>
                                    ) : <></>}
                                </ul>
                            </div>
                        </li>

                    <hr className="horizontal dark mt-2" />

                    <li className={"nav-item"}>
                            <a data-bs-toggle="collapse" href="#customer-managment" className={nowDrop === 'customer-managment' ? activeClassName : navClassName} aria-controls="customer-managment" role="button" aria-expanded={nowDrop === 'customer-managment'}>
                                <i className="fa-solid fa-users icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                <span className="nav-link-text ms-1">{t('Customer Managment')}</span>
                            </a>
                            <div className={nowDrop === 'customer-managment' ? dropdownClassShow : dropdownClass} id="customer-managment">
                                <ul className="nav ms-4 ps-3">
                                    {Cookies.get('permissions').indexOf("customer-read") !== -1 ? (
                                        <>
                                            <li className="nav-item">
                                                <NavLink to="/customer-managment/customer" onClick={(e) => setNowDrop('customer-managment')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                    <span className="sidenav-normal">{t('Customer')}</span>
                                                </NavLink>
                                            </li>
                                        </>
                                    ) : <></>}

                                    {Cookies.get('permissions').indexOf("lead_managment-read") !== -1 ? (
                                        <>
                                            <li className="nav-item">
                                                <a data-bs-toggle="collapse" href="#lead" className={childDrop === 'lead-managment' ? activeClassName : navClassName} aria-controls="lead" role="button" aria-expanded={childDrop === 'lead-managment'}>
                                                    <span className="nav-link-text">{t('Lead Managment')}</span>
                                                </a>
                                                <div className={childDrop === 'lead-managment' ? dropdownClassShow : dropdownClass} id="lead">
                                                    <ul className="nav">
                                                        <li className="nav-item">
                                                            <NavLink to="/customer-managment/lead-managment/leads" onClick={(e) => setNowDrop('customer-managment')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                <span className="sidenav-normal">{t('Leads')}</span>
                                                            </NavLink>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </>
                                    ) : <></>}
                                </ul>
                            </div>
                        </li>


                    {Cookies.get('permissions').indexOf("quotation-read") !== -1 ||
                        Cookies.get('permissions').indexOf("quotation-read") !== -1 ? (
                        <li className={"nav-item"}>
                            <a data-bs-toggle="collapse" href="#marketing-sales" className={nowDrop === 'marketing-sales' ? activeClassName : navClassName} aria-controls="marketing-sales" role="button" aria-expanded={nowDrop === 'marketing-sales'}>
                                <i className="fa-solid fa-bag-shopping icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                {/* <svg class="icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z" clip-rule="evenodd"></path></svg> */}
                                <span className="nav-link-text ms-1">{t('Marketing & Sales')}</span>
                            </a>
                            <div className={nowDrop === 'marketing-sales' ? dropdownClassShow : dropdownClass} id="marketing-sales">
                                <ul className="nav ms-4 ps-3">
                                    {Cookies.get('permissions').indexOf("quotation-read") !== -1 ? (
                                        <>
                                            <li className="nav-item">
                                                <NavLink to="/marketing-sales/quotations" onClick={(e) => setNowDrop('marketing-sales')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                    <span className="sidenav-normal">{t('Angebote')}</span>
                                                </NavLink>
                                            </li>
                                        </>
                                    ) : <></>}


                                    {Cookies.get('permissions').indexOf("order-read") !== -1 ? (
                                        <>
                                            <li className="nav-item">
                                                <NavLink to="/marketing-sales/orders" onClick={(e) => setNowDrop('marketing-sales')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                    <span className="sidenav-normal">{t('Auftrag')}</span>
                                                </NavLink>
                                            </li>
                                        </>
                                    ) : <></>}

                                    
                                </ul>
                            </div>
                        </li>
                    ) : <></>}


                    {Cookies.get('permissions').indexOf("partner_company-read") !== -1 ? (
                        <li className={"nav-item"}>
                        <a data-bs-toggle="collapse" href="#purchasing" className={nowDrop === 'purchasing' ? activeClassName : navClassName} aria-controls="sales-distribution" role="button" aria-expanded={nowDrop === 'sales-distribution'}>
                            <i className="fa-solid fa-bag-shopping icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            {/* <svg class="icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z" clip-rule="evenodd"></path></svg> */}
                            <span className="nav-link-text ms-1">{t('Purchasing')}</span>
                        </a>
                        <div className={nowDrop === 'purchasing' ? dropdownClassShow : dropdownClass} id="purchasing">
                            <ul className="nav ms-4 ps-3">

                                <li className="nav-item">
                                    <NavLink to="/purchasing/lieferanten-partner" onClick={(e) => setNowDrop('purchasing')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                        <span className="sidenav-normal">{t('Partner Companies')}</span>
                                    </NavLink>
                                </li>
                                
                            </ul>
                        </div>
                    </li>
                    ):<></>  }

                    {Cookies.get('permissions').indexOf("invoice-read") !== -1 ||
                        Cookies.get('permissions').indexOf("invoice-read") !== -1 ? (
                        <li className={"nav-item"}>
                            <a data-bs-toggle="collapse" href="#finance" className={nowDrop === 'finance' ? activeClassName : navClassName} aria-controls="sales-distribution" role="button" aria-expanded={nowDrop === 'sales-distribution'}>
                            <i className="fa-solid fa-coins icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                {/* <svg class="icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z" clip-rule="evenodd"></path></svg> */}
                                <span className="nav-link-text ms-1">{t('Finance')}</span>
                            </a>
                            <div className={nowDrop === 'finance' ? dropdownClassShow : dropdownClass} id="finance">
                                <ul className="nav ms-4 ps-3">
                                    {Cookies.get('permissions').indexOf("invoice-read") !== -1 ? (
                                        <li className="nav-item">
                                            <NavLink to="/finance/invoices" onClick={(e) => setNowDrop('finance')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('Invoices')}</span>
                                            </NavLink>
                                        </li>
                                    ):<></>}
                                    
                                    {Cookies.get('permissions').indexOf("incoming_invoice-read") !== -1 ? (<li className="nav-item">
                                        <NavLink to="/finance/incoming-invoices" onClick={(e) => setNowDrop('finance')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                            <span className="sidenav-normal">{t('Incoming Invoices')}</span>
                                        </NavLink>
                                    </li>):<></>}
                                </ul>
                            </div>
                        </li>
                    ) : <></>}


                    {Cookies.get('permissions').indexOf("employee-read") !== -1 ? (
                        <li className={"nav-item"}>
                            <a data-bs-toggle="collapse" href="#hr" className={nowDrop === 'hr' ? activeClassName : navClassName} aria-controls="hr" role="button" aria-expanded={nowDrop === 'hr'}>
                                <i className="fa-solid fa-bag-shopping icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                {/* <svg class="icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z" clip-rule="evenodd"></path></svg> */}
                                <span className="nav-link-text ms-1">{t('Human Resources')}</span>
                            </a>
                            <div className={nowDrop === 'hr' ? dropdownClassShow : dropdownClass} id="hr">
                                <ul className="nav ms-4 ps-3">

                                    {Cookies.get('permissions').indexOf("employee-read") !== -1 ? (
                                        <>
                                            <li className="nav-item">
                                                <NavLink to="/hr/employee" onClick={(e) => setNowDrop('hr')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                    <span className="sidenav-normal">{t('Employee')}</span>
                                                </NavLink>
                                            </li>
                                        </>
                                    ) : <></>}
                                    
                                </ul>
                            </div>
                        </li>
                    ):<></>  }

                    {Cookies.get('permissions').indexOf("settings-read") !== -1 ||
                        Cookies.get('permissions').indexOf("settings-read") !== -1 ? (
                        <li className={"nav-item"}>
                            <a data-bs-toggle="collapse" href="#setting" className={nowDrop === 'setting' ? activeClassName : navClassName} aria-controls="setting" role="button" aria-expanded={nowDrop === 'setting'}>
                                <i className="fa-solid fa-gear icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                {/* <svg class="icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z" clip-rule="evenodd"></path></svg> */}
                                <span className="nav-link-text ms-1">{t('Settings')}</span>
                            </a>
                            <div className={nowDrop === 'setting' ? dropdownClassShow : dropdownClass} id="setting">
                                <ul className="nav ms-4 ps-3">
                                    <li className="nav-item">
                                        <NavLink to="/settings/roles" onClick={(e) => setNowDrop('setting')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                            <span className="sidenav-normal">{t('Role')}</span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/settings/profile" onClick={(e) => setNowDrop('setting')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                            <span className="sidenav-normal">{t('Profile')}</span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/settings/smtp" onClick={(e) => setNowDrop('setting')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                            <span className="sidenav-normal">{t('Smtp')}</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    ) : <></>}
                </ul>
            </div>
        </aside>
    );
}

export default Sidebar;
