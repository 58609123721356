import { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import Cookies from 'js-cookie';
import callFetch from 'helpers/callFetch';
import deleteAlert from 'helpers/deleteAlert';
import { Company } from 'context/DashboardContext/DashboardContext';
import { NumericFormat } from 'react-number-format';
import dateFormat from "dateformat";
import SoftBadge from 'components/SoftBadge';
const ClientBseOrders = () => {
  const params = useParams();
  const navigate = useNavigate();
  const {dashboardlogo} = Company();
  const { t } = useTranslation();
  const [tabledata, setTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [searchKey, setSearchKey] = useState("")
  const [filterData, setFilterData] = useState([])
  const tableHeadings = [
      {
          name: t('Order Nr'),
          selector: row => <NavLink to={'/marketing-sales/orders/' + row.id + '/edit'}>{row.order_number}</NavLink>,
          sortable: true,
      },
      {
          name: t('Client Name'),
          selector: row => row?.quotation?.client?.company_name,
      },
      {
        name: t('Sales Person'),
        selector: row => "---",
      },
      {
          name: t('Date'),
          selector: row => dateFormat(row.order_date, "dd.mm.yyyy"),
      },
      {
          name: t('Order Amount'),
          selector: row => <NumericFormat 
                              value={JSON.parse(row.product_details)?.total}
                              displayType="text" 
                              thousandSeparator={"."} 
                              decimalSeparator=","
                              decimalScale={2}
                              fixedDecimalScale
                              suffix={row.currency == 'EUR' ? ' € ' : ' $ '}
                          /> ,
      },
      {
        name: t('Status'),
        selector: row => <SoftBadge
                            variant="contained"
                            color={row.status === "Order"?"error":"success"}
                            size="xs"
                            badgeContent={row.status}
                            container
                            />,
      },
      {
          name: t('Actions'),
          cell: row => (
              <>
                  {Cookies.get('permissions').indexOf("order-update") !== -1 ||
                      Cookies.get('permissions').indexOf("order-delete") !== -1 ? (
                      <div className="text-center dropstart">
                          <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                              <i className="fa fa-ellipsis-v text-xs"></i>
                          </a>
                          <ul className="dropdown-menu">
                              {Cookies.get('permissions').indexOf("invoice-create") !== -1 ? (
                                  <>
                                      <li>
                                          <NavLink to={'/finance/invoices/' + row.id + '/create'} className="dropdown-item">
                                              {t('Create Invoice')}
                                          </NavLink>
                                      </li>
                                      <li><hr className="dropdown-divider" /></li>
                                  </>
                              ) : <></>}
                              {Cookies.get('permissions').indexOf("order-update") !== -1 ? (
                                  <>
                                      <li>
                                          <NavLink to={'/marketing-sales/orders/' + row.id + '/edit'} className="dropdown-item">
                                              {t('Edit')}
                                          </NavLink>
                                      </li>
                                      <li><hr className="dropdown-divider" /></li>
                                  </>
                              ) : <></>}
                              {Cookies.get('permissions').indexOf("order-delete") !== -1 ? (
                                  <li><a className="dropdown-item text-danger" href="#0" onClick={(e) => deleteAlert(e, 'orders', row.id+`?company=${dashboardlogo}`, t).then(res => setRefresh(refresh + 1))}>{t('Delete')}</a></li>
                              ) : <></>}
                          </ul>
                      </div>
                  ) : <></>}
              </>
          )
      }
  ];

  useEffect(() => {
      if(!dashboardlogo){
        navigate('/dashboard');
      }
    callFetch("customers/customer-base-data/"+params.id+"?page=" + pageNumber+`&company=${dashboardlogo}`, "GET", []).then((res) => {
        setTableData(res?.data?.order);
    });
  }, [pageNumber, refresh]);

  useEffect(() => {
    if (searchKey.length > 0) {
        callFetch("orders/search/" + searchKey+`?company=${dashboardlogo}`, "GET", []).then((res) => {
            setFilterData(res?.data?.data)
            setTableData(res?.data);
        })
    } else {
        setRefresh(refresh + 1)
    }

}, [searchKey])

  const handlePageChange = page => {
      setPageNumber(page);
  }

  function toPages(pages) {
      const results = [];

      for (let i = 1; i <= pages; i++) {
          results.push(i);
      }

      return results;
  }

  // RDT exposes the following internal pagination properties
  const BootyPagination = ({
      onChangePage,
      currentPage
  }) => {
      const handleBackButtonClick = () => {
          onChangePage(currentPage - 1);
      };

      const handleNextButtonClick = () => {
          onChangePage(currentPage + 1);
      };

      const handlePageNumber = (e) => {
          onChangePage(Number(e.target.value));
      };

      const pages = tabledata.last_page;
      const pageItems = toPages(pages);
      const nextDisabled = currentPage === tabledata.last_page;
      const previosDisabled = currentPage === 1;

      return (
          <>
              <br />
              <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
              <nav className="float-md-end me-2">
                  <ul className="pagination">
                      <li className="page-item">
                          <button
                              className="page-link"
                              onClick={handleBackButtonClick}
                              disabled={previosDisabled}
                              aria-disabled={previosDisabled}
                              aria-label="previous page"
                          >
                              &#60;
                          </button>
                      </li>
                      {pageItems.map((page) => {
                          const className =
                              page === currentPage ? "page-item active" : "page-item";

                          return (
                              <li key={page} className={className}>
                                  <button
                                      className="page-link"
                                      onClick={handlePageNumber}
                                      value={page}
                                  >
                                      {page}
                                  </button>
                              </li>
                          );
                      })}
                      <li className="page-item">
                          <button
                              className="page-link"
                              onClick={handleNextButtonClick}
                              disabled={nextDisabled}
                              aria-disabled={nextDisabled}
                              aria-label="next page"
                          >
                              &#62;
                          </button>
                      </li>
                  </ul>
              </nav>
              <div className="clearfix"></div>
          </>
      );
  };

  return <DataTable
      columns={tableHeadings}
      data={tabledata?.data}
      noDataComponent={t('There are no records to display')}
      pagination
      highlightOnHover
      paginationComponentOptions={{ noRowsPerPage: true }}
      paginationServer
      paginationTotalRows={tabledata?.total}
      onChangePage={handlePageChange}
      paginationComponent={BootyPagination}
      subHeader
      subHeaderComponent={<input type="text" className=' form-control w-sm-50 w-md-25 w-lg-15' value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />}
  />;
}

export default ClientBseOrders