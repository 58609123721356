import { Card } from '@mui/material'
import React from 'react'

const ProfileAvatar = ({src,alt,shadow,width,height,rounded}) => {
  return (
    <Card className={shadow} sx={{ width:`${width}`, height:`${height}`, overflow:"hidden",display: "flex", justifyContent: "center", alignItems: "center", borderRadius:`${rounded}` }} >
        <img
            style={{
            maxWidth:"100%",
            height:"auto",
            display:"block",
            margin:"0 auto",
            }}
            src={src}
            alt={alt}
        />
    </Card>
  )
}

export default ProfileAvatar