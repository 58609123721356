import SoftEditor from 'components/SoftEditor';
import { Company } from 'context/DashboardContext/DashboardContext';
import callFetch from 'helpers/callFetch';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const EmailSend = ({title,data},props) => {
    const { dashboardlogo } = Company();
    const params = useParams();
    const { t } = useTranslation();
    const [emails,setEmails] = useState([{id:0,email:""}]);
    const [customerId, setCustomerId] = useState(0);
    const [saving, setSaving] = useState(false);
    const [invoicPdf, setInvoicePdf] = useState('');
    const [link, setLink] = useState('');
    const [description, setDescription] = useState('');
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    function deleteProduct() {
        var titems = [];
        emails.map((t) => {
            if (!t)
                return;
            titems.push(t);
        });
        setEmails(titems);
    }
    

    // useEffect(()=>{
    //     const explode = props?.pdf?.split('/');
    //     if(explode) {
    //         const pdflink = explode[3]+"/"+explode[4]+"/"+explode[5]
    //         setLink(pdflink)
    //     }
    // },[props?.pdf])

    const setEditorValue = (e) =>{
        setDescription(e)
    }
const onSubmit = (formData) => {
    setSaving(true);
    formData.id = params?.id;
    formData.emails = JSON.stringify(emails)
    formData.description = description;
    callFetch("SendEmail?company="+dashboardlogo, "POST", formData, setError).then((res) => {
        setSaving(false);
        if (!res.ok) return;
    });
};
return (
    <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('E-Mail')}</h6>
                        </div>
                        <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                <input type="hidden" {...register("id")} value={props.id} />
                                <div className="form-group">
                                    <label>{title} *</label>
                                    <select
                                        className="form-control"
                                        {...register("type", {
                                            required: true,
                                        })}
                                        required>
                                        <option value="">{t('Select')}</option>
                                        {
                                            title === "Quotation" && <option value="quotation">{t('Quotation PDF')}</option>
                                        }
                                        {
                                            data && data?.quotaion !== null && <option value="quotation">{t('Quotation PDF')}</option>
                                        }

                                        {
                                            title === "Order" && <><option value="quotation">{t('Quotation PDF')}</option><option value="order">{t('Order PDF')}</option></>
                                        }
                                        {
                                            data && data?.order !== null && <option value="order">{t('Order PDF')}</option>
                                        }
                                        

                                        {
                                            title === "Invoice" && <option value="invoice">{t('Invoice PDF')}</option>
                                        }
                                        
                                    </select>
                                    <div className="invalid-feedback">{errors.status && errors.status.message}</div>
                                </div>

                                {
                                    emails && emails?.map((email,i) =>(
                                        <div key={i} className="form-group">
                                            <label>{t('Email')} *</label>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" placeholder="eg. email@mail.com"
                                                    onChange={(e) =>{
                                                        emails[i].email = e.target.value;
                                                        setEmails(emails);
                                                    }}
                                                />
                                                <div className="input-group-append d-flex align-items-center">
                                                    &nbsp;
                                                    <i className="fa-solid fa-circle-xmark text-danger cursor-pointer" data-key={i} onClick={() => { delete emails[i]; deleteProduct(); }}></i>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                                <div className="form-group mb-3">
                                    <a onClick={()=>{setEmails([...emails,{id:0,email:""}])}}><i className="fa-solid fa-circle-plus"></i>&nbsp;Add</a>
                                </div>
                                

                                
                                <div className="row g-3">                     
                                    <div className="col-12 my-4">
                                        {!saving && (
                                            <button type="submit" className="btn btn-primary">
                                                {t('Send')}
                                            </button>
                                        )}
                                        {saving && (
                                            <button type="submit" className="btn btn-disabled" disabled>
                                                {t('Sending ...')}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </form>

                            <div className="">
                                <SoftEditor onChange={setEditorValue} />
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
  )
}

export default EmailSend