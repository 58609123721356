import { Divider } from '@mui/material';
import SoftTypography from 'components/SoftTypography';
import callFetch from 'helpers/callFetch';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePicker from "react-multi-date-picker"
import transition from "react-element-popper/animations/transition"
import RoutenCard from './RoutenCard';
const RoutenplanerModal = (props) => {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [isData,setIsData] = useState(false);
    const [refresh, setRefresh] = useState(0);

    // multiple datePicker
    const today = new Date()
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    const [values, setValues] = useState([])

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        reset
    } = useForm();

    let serial = 1;

    useEffect(() => {
    }, [values]);

    const onSubmit = (formData) => {
        setSaving(true);
        callFetch("departments", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            reset();
            setRefresh(refresh + 1);
            props.refreshParent();
        });
    };

    const RouteCard = [
        {
            title:"Route 1",
            data:[
                {id:1, title:"Route 1", address:"82057, Icking - Harald Vogel", telephone:"0178299...", date:"01.08.2023", start:"13:00", end:"18:00"},
                {id:2, title:"Route 2", address:"82057, Icking - Harald Vogel", telephone:"0178299...", date:"01.08.2023", start:"13:00", end:"18:00"},
                {id:3, title:"Route 3", address:"82057, Icking - Harald Vogel", telephone:"0178299...", date:"01.08.2023", start:"13:00", end:"18:00"},
                {id:4, title:"Route 4", address:"82057, Icking - Harald Vogel", telephone:"0178299...", date:"01.08.2023", start:"13:00", end:"18:00"},
                {id:5, title:"Route 5", address:"82057, Icking - Harald Vogel", telephone:"0178299...", date:"01.08.2023", start:"13:00", end:"18:00"}
            ]
        },
        {
            title:"Route 2",
            data:[
                {id:1, title:"Route 1", address:"82057, Icking - Harald Vogel", telephone:"0178299...", date:"01.08.2023", start:"13:00", end:"18:00"},
                {id:2, title:"Route 2", address:"82057, Icking - Harald Vogel", telephone:"0178299...", date:"01.08.2023", start:"13:00", end:"18:00"},
                {id:3, title:"Route 3", address:"82057, Icking - Harald Vogel", telephone:"0178299...", date:"01.08.2023", start:"13:00", end:"18:00"},
            ]
        },
        {
            title:"Route 3",
            data:[
                {id:1, title:"Route 1", address:"82057, Icking - Harald Vogel", telephone:"0178299...", date:"01.08.2023", start:"13:00", end:"18:00"},
                {id:2, title:"Route 2", address:"82057, Icking - Harald Vogel", telephone:"0178299...", date:"01.08.2023", start:"13:00", end:"18:00"},
                {id:3, title:"Route 3", address:"82057, Icking - Harald Vogel", telephone:"0178299...", date:"01.08.2023", start:"13:00", end:"18:00"},
                {id:4, title:"Route 4", address:"82057, Icking - Harald Vogel", telephone:"0178299...", date:"01.08.2023", start:"13:00", end:"18:00"},
            ]
        },
        {
            title:"Route 4",
            data:[
                {id:1, title:"Route 1", address:"82057, Icking - Harald Vogel", telephone:"0178299...", date:"01.08.2023", start:"13:00", end:"18:00"},
                {id:2, title:"Route 2", address:"82057, Icking - Harald Vogel", telephone:"0178299...", date:"01.08.2023", start:"13:00", end:"18:00"},
                {id:3, title:"Route 3", address:"82057, Icking - Harald Vogel", telephone:"0178299...", date:"01.08.2023", start:"13:00", end:"18:00"},
                {id:4, title:"Route 4", address:"82057, Icking - Harald Vogel", telephone:"0178299...", date:"01.08.2023", start:"13:00", end:"18:00"},
                {id:5, title:"Route 5", address:"82057, Icking - Harald Vogel", telephone:"0178299...", date:"01.08.2023", start:"13:00", end:"18:00"}
            ]
        }
        
    ]


    return (
        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <div className="modal fade" id="RoutenModal" >
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content mt-7">
                        {/* <div className="modal-header">
                            
                            <button type="button" className="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div> */}
                        <div className="modal-body pb-5">
                            <h5 className="modal-title mt-3" style={{ fontSize:"18px"}} id="departmentModalLabel">{t('Routenplaner')}</h5>
                            <div className="row mt-4">
                                <div className="col-12 col-md-3 col-lg-3 col-xl-3">
                                    <div className="form-group">
                                        <label>
                                        {t('Anzahl der Routen')}
                                        </label>
                                        <select
                                            className="form-control"
                                            {...register("routes")}>
                                            <option value="">Select Routes</option>
                                            <option value="1">{t('1')}</option>
                                            <option value="2">{t('2')}</option>
                                            <option value="3">{t('3')}</option>
                                            </select>
                                        <div className="invalid-feedback">{errors.gender && errors.gender.message}</div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-3 col-lg-3 col-xl-3">
                                    <div className="form-group">
                                        <label>
                                        {t('Start date')}
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control mb-4 flatpickr"
                                            placeholder={t('Please select start date')}
                                            {...register("joining_date", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.gender && errors.gender.message}</div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-3 col-lg-3 col-xl-3">
                                    <div className="form-group">
                                        <label>
                                        {t('End date')}
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control mb-4 flatpickr"
                                            placeholder={t('Please select end date')}
                                            {...register("joining_date", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.gender && errors.gender.message}</div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-3 col-lg-3 col-xl-3 d-grid">
                                    
                                        <label>{t('Absence')}</label>
                                        {/* <input
                                            type="date"
                                            className="form-control mb-4 flatpickr"
                                            placeholder={t('Please select absence date')}
                                            {...register("joining_date", {
                                                required: true,
                                            })}
                                            multiple={true}
                                            required
                                        /> */}
                                        <DatePicker
                                            inputClass="form-control mb-4 flatpick"
                                            format="M/D/YYYY"
                                            multiple
                                            value={values} 
                                            onChange={(array)=>setValues(array)}
                                            placeholder="Please select end date"
                                        />
                                        
                                    <div className="invalid-feedback">{errors.gender && errors.gender.message}</div>
                                   
                                </div>

                            </div>
                            <Divider/>
                            {
                                isData ? 
                                <div className="mt-3">
                                    <div className="row ">
                                        <div className="col-12">
                                                    {
                                                        RouteCard && RouteCard.map((element, index)=>
                                                            <div className="row d-flex" key={index}>
                                                                <div className="col-9">
                                                                    <div className="z-index-1" >
                                                                        <SoftTypography variant="h5" fontSize="14px" className={`mb-2 ${index === 0 ? 'mt-4':'mt-4'} d-inline-block`} >{element.title}</SoftTypography>
                                                                        <div className="d-flex overflow-auto scrollbar">
                                                                            {
                                                                                element?.data && element?.data.map((item,index)=>
                                                                                    <div key={index}>
                                                                                        <RoutenCard data={item} />
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-3">
                                                                    <div className="mt-5">
                                                                        <div className="form-group">
                                                                            <label>
                                                                            {t('Team Zuweisen')}
                                                                            </label>
                                                                            <select className="form-control" {...register("routes")} value={element.title}>
                                                                                <option value="">Select Routes</option>
                                                                                <option value="Route 1">{t('Route 1')}</option>
                                                                                <option value="Route 2">{t('Route 2')}</option>
                                                                                <option value="Route 3">{t('Route 3')}</option>
                                                                            </select>
                                                                            <div className="invalid-feedback">{errors.gender && errors.gender.message}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                
                                        </div>

                                    </div>
                                </div>
                                :
                                <div className="d-flex align-items-center justify-content-center mb-9 mt-7">
                                    <div className="d-grid">
                                        <button onClick={()=>setIsData(!isData)} className="btn btn-success" style={{ background:"#48BB78" }} >Approve</button>
                                        <SoftTypography variant="caption" >Approve for planing the Routes</SoftTypography>
                                    </div>
                                </div>
                            }
                            

                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default RoutenplanerModal