import { Close } from '@mui/icons-material';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import CropEasy from './components/crop/CropEasy';

const ImagePopup = ({showModal,openModal,WithoutCrop, photoURL,croppedPhoto, filePhoto}) => {
const [modal, setModal] = useState(false)
    const getFile = (e) =>{
        croppedPhoto(e)
    }
    const photoUrl = (e) =>{
    }

  return (
    <Dialog open={showModal} onClose={openModal}>
      <DialogTitle>
        <IconButton
          aria-label="Close"
          onClick={openModal}
          sx={{
            position: 'absolute',
            display: "flex",
            alignItems: 'center',
            right: 0,
            top: 0,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <CropEasy WithoutCrop={WithoutCrop} setOpenCrop={openModal} photoURL={photoURL} setPhotoURL={photoUrl} setFile={getFile} filePhoto={filePhoto} />
    </Dialog>
  );
};

export default ImagePopup;
