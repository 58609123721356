import { React, useEffect, useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import Cookies from 'js-cookie';
import SoftBadge from 'components/SoftBadge';
import { Company } from 'context/DashboardContext/DashboardContext';
import callFetch from 'helpers/callFetch';
import deleteAlert from 'helpers/deleteAlert';
import ProfileAvatar from './components/ProfileAvatar';
//import ImportModal from '../../importer/ImportModal';

function CustomerIndexTable() {
    const navigate = useNavigate();
    const { dashboardlogo } = Company();
    const { t } = useTranslation();
    const [employees, setEmployees] = useState([]);
    const [roles, setRoles] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [searchKey, setSearchKey] = useState("")
    const [searchData, setSearchData] = useState([])
    const [filterData, setFilterData] = useState([])

    const tableHeadings = [
        {
            name: t('Customer Nr.'),
            width: '180px',
            sortable: true,
            selector: row => <NavLink to={'/customer-managment/customer/' + row.id + '/details'}>{row.client_number}</NavLink>
        },
        {
            name: t('Name'),
            width: '180px',
            sortable: true,
            selector: row => <div className="row mt-1 d-flex align-items-center" style={{ width: '180px' }}>
                <div className="col-4 pe-0">
                    {/* <img className="avatar avatar-sm" src={row.photo ? process.env.REACT_APP_STORAGE_URL + row.photo : '/favicon.png'} alt="" /> */}
                    <ProfileAvatar
                        width="36px" 
                        height="36px"
                        src={row.photo ? process.env.REACT_APP_STORAGE_URL + row.photo : '/favicon.png'}
                        rounded="0.75rem"
                    />
                </div>
                <div className="col-8 ps-0">
                    <p className="mb-0 text-xs text-wrap">{row.company_name}</p>
                </div>
            </div>
        },
        {
            name: t('Responsible Person'),
            sortable: true,
            selector: row => row.responsible_persons !== null && <>
                {
                    JSON.parse(row?.responsible_persons).map((perosn, index) =>(
                        <>
                            {perosn?.name +" " + perosn?.surname} <br />
                        </>
                    ))
                }
            </>
        },

        {
            name: t('Country'),
            sortable: true,
            selector: row => row.country
        },
        {
            name: t('Phone'),
            sortable: true,
            selector: row => row.phone
        },
        {
            name: t('Status'),
            sortable: true,
            selector: row => <SoftBadge
                                variant="contained"
                                color={row.status === "active"?"success":"error"}
                                size="xs"
                                badgeContent={row.status}
                                container
                            />
        },
        
        {
            name: t('Actions'),
            sortable: true,
            cell: row => (
                <>
                    {Cookies.get('permissions').indexOf("customer-update") !== -1 ||
                        Cookies.get('permissions').indexOf("customer-delete") !== -1 ? (
                        <div className="text-center dropstart">
                            <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-ellipsis-v text-xs"></i>
                            </a>
                            <ul className="dropdown-menu">
                                {Cookies.get('permissions').indexOf("customer-update") !== -1 ? (
                                    <li>
                                        <NavLink to={'/customer-managment/customer/' + row.id + '/edit'} className="dropdown-item">
                                            {t('Edit')}
                                        </NavLink>
                                    </li>
                                ) : <></>}
                                <li><hr className="dropdown-divider" /></li>
                                {Cookies.get('permissions').indexOf("customer-delete") !== -1 ? (
                                    <li><a className="dropdown-item text-danger" href="#0" onClick={(e) => deleteAlert(e, 'customers', row.id, t).then(res => setRefresh(refresh + 1))}>{t('Delete')}</a></li>
                                ) : <></>}
                            </ul>
                        </div>
                    ) : <></>}
                </>
            )
        }
    ];




    useEffect(() => {
        if(!dashboardlogo){
            navigate('/dashboard');
          }
        callFetch("customers?page=" + pageNumber+`&company=${dashboardlogo}`, "GET", []).then((res) => {
            setEmployees(res?.data);
            setFilterData(res?.data?.data);
            // setRoles(res?.roles);
        });
    }, [pageNumber, refresh, dashboardlogo]);

    useEffect(() => {
        if (searchKey.length > 0) {
            callFetch("customers/search/" + searchKey+`?company=${dashboardlogo}`, "GET", []).then((res) => {
                setFilterData(res.data.data)
                setEmployees(res.data);
            })
        } else {
            setRefresh(refresh + 1)
        }

    }, [searchKey])

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = employees.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === employees.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {employees.from} {t('to')} {employees.to} {t('of')} {employees.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>
                        {pageItems.map((page) => {
                            const className =
                                page === currentPage ? "page-item active" : "page-item";

                            return (
                                <li key={page} className={className}>
                                    <button
                                        className="page-link"
                                        onClick={handlePageNumber}
                                        value={page}
                                    >
                                        {page}
                                    </button>
                                </li>
                            );
                        })}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return <>
        <DataTable
            columns={tableHeadings}
            data={filterData}
            noDataComponent={t('There are no records to display')}
            className='data-table'
            pagination
            highlightOnHover
            paginationComponentOptions={{ noRowsPerPage: true }}
            paginationServer
            paginationTotalRows={employees?.total}
            onChangePage={handlePageChange}
            paginationComponent={BootyPagination}
            subHeader
            subHeaderComponent={<input type="text" className=' form-control w-sm-50 w-md-25 w-lg-15' value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />}
        />
        {/*
        <ImportModal type={'employee'} refreshParent={() => setRefresh(refresh + 1)} />
        */}
    </>
}

export default CustomerIndexTable;
