import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import { NumericFormat } from 'react-number-format';
import SoftBadge from 'components/SoftBadge';

const OpenInvoiceIndexTable = () => {
    const { t } = useTranslation();
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [loading, setLoading] = useState(false);


    const status = (data) => {

        var status_30_percent = false;
        var status_70_percent = false;
        var all_invoice_created = false;
        var status = '';

        for (let [key, value] of Object.entries(data)) {
            if (data[key]?.product_details) {
                var product_details = JSON.parse(data[key]?.product_details);
                if (product_details?.amountPerchantage == 30) {
                    status_30_percent = true;
                } else if (product_details?.amountPerchantage == 70) {
                    status_70_percent = true;
                }
            }
        }

        if (status_30_percent && status_70_percent) {
            all_invoice_created = true;
        }

        status = (status_70_percent == false ? '70% Rechnung'
            : status_30_percent == false ? '30% Rechnung'
                : all_invoice_created ? 'Rechnungen erstellt'
                    : '');

        return (
            <>
                <SoftBadge
                    variant="contained"
                    size="xs"
                    badgeContent={status}
                    color={
                        status === "30% Rechnung" ? "info" :
                            status === "70% Rechnung" ? "warning" :
                                status === "Rechnungen erstellt" ? "success" : ''
                    }
                    container
                />
            </>
        );
    }

    const tableHeadings = [
        {
            name: t('Projektnr.'),
            selector: row => (
                <NavLink to={'/project-management/project/edit/' + row?.id} className="text-primary">{row?.id}</NavLink>
            ),
            sortable: true,
        },
        {
            name: t('Kunde'),
            selector: row => (row?.kunde),
        },
        {
            name: t('VKB'),
            selector: row => (row?.salse_person?.name),
        },
        {
            name: t('Lieferdatum Speicher'),
            selector: row => (row?.purchase?.siefertermin_speicher ? dateFormat(row?.purchase?.siefertermin_speicher, "dd.mm.yyyy") : '--'),
        },
        {
            name: t('Auftrag Gesamt'),
            selector: row => (
                <>
                    <NumericFormat
                        value={Number(row?.quations?.order?.netto)}
                        displayType="text"
                        thousandSeparator={"."}
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        suffix={row?.quations?.order?.currency == 'EUR' ? ' € ' : ' $ '}
                    />
                    {/* <NumericFormat
                        value={Number(JSON.parse(row?.product_details)?.total)}
                        displayType="text"
                        thousandSeparator={"."}
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        suffix={row.currency == 'EUR' ? ' € ' : ' € '}
                    /> */}
                </>
            ),
        },
        {
            name: t('Status'),
            selector: row => status(row?.quations?.order?.invoices ? row?.quations?.order?.invoices : row?.quations?.order?.invoices),
        },
    ];

    useEffect(() => {
        setLoading(true);
        callFetch("open-invoice?page=" + pageNumber, "GET", []).then((res) => {
            setTableData(res.data);
            //console.log(res.data)
            setLoading(false);
        });
        //setTableData(data)
    }, [pageNumber, refresh]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />

                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>

                        {tabledata?.links.map((page) => {
                            //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                            const className = (page.active === true ? "page-item active" : "page-item");
                            return (
                                <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                    <button
                                        className="page-link"
                                        onClick={page.label != '...' ? handlePageNumber : ''}
                                        value={page.label}
                                        disabled={page.label == '...' ? true : false}
                                    >
                                        {page.label}
                                    </button>
                                </li>
                            );
                        })}

                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return <>
        <DataTable
            columns={tableHeadings}
            data={tabledata?.data}
            noDataComponent={loading ? t('Loading...') : t('There are no records to display')}
            className='data-table'
            pagination
            highlightOnHover
            paginationComponentOptions={{ noRowsPerPage: true }}
            paginationServer
            paginationTotalRows={tabledata?.total}
            onChangePage={handlePageChange}
            paginationComponent={BootyPagination}
            subHeader
        />;
    </>
}

export default OpenInvoiceIndexTable