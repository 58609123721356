import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import LieferantenIndexTable from './LieferantenIndexTable'

const LieferantenIndex = () => {
    const { t } = useTranslation()
    return (
      <>
              <div className="d-sm-flex justify-content-between">
                  <div className="relative-h">
                      <NavLink to="/purchasing/lieferanten-partner/create" className="btn btn-icon btn-primary">
                          {t('Partner Company Add')}
                      </NavLink>
                  </div>
              </div> 
              <div className="row">
                  <div className="col-12">
                      <div className="card mb-4">
                          <div className="card-header pb-0">
                              <h6>{t('All Partner Companies')}</h6>
                          </div>
                          <div className="card-body px-0 pt-0 pb-2">
                              <LieferantenIndexTable/>
                          </div>
                      </div>
                  </div>
              </div>
          </>
    )
}

export default LieferantenIndex