import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import flatpickr from "flatpickr";
import SoftAlert from "components/SoftAlert";
import SoftSnackbar from "components/SoftSnackbar";
import { Company } from "context/DashboardContext/DashboardContext";

const SmtpEdit = () => {
  const params = useParams();
  const { dashboardlogo } = Company();
  const { t } = useTranslation();
  const [currency, setCurrency] = useState('EUR');
  const [data, setData] = useState([]);
  const [saving, setSaving] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [refresh2, setRefresh2] = useState(0);
  const [receivedError, setReceivedError] = useState(null);
  const [einkaufData, setEinkaufData] = useState([])
  const [companies, setCompanies] = useState([])
   const {
      register,
      handleSubmit,
      setError,
      setValue,
      formState: { errors },
  } = useForm();
  


  useEffect(() => { }, [refresh2]);

//   useEffect(() => {
//     flatpickr(".flat-pickr");
//     callFetch("smtp/create", "GET", []).then((res) => {
//         setCompanies(res?.data)
//         setRefresh2(refresh2 + 1)
//     });
// }, [0]);

  useEffect(() => { 
      if(params?.id){
        callFetch('smtp/'+params?.id+'/edit', "GET", []).then((res)=>{
          for (let [key, value] of Object.entries(res.data)) {
            setValue(key, (value == null || value == 'null' ? "" : value ));
          }
        })
      }
  },[params?.id]);

  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const renderSuccessSB = (
      <SoftSnackbar
        color="success"
        icon="check"
        title="Success"
        content="Invoice successfully created"
        dateTime="Just Now"
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
      />
    );

  const onSubmit = (formData) => {
       setSaving(true);
      //formData.company = dashboardlogo
      callFetch("smtp/"+params?.id, "POST", formData, setError).then((res) => {
          setSaving(false);
          if (!res.ok) return;
          setSubmitSuccess(true);
      }); 
  };

return submitSuccess ? <Navigate to={`/settings/smtp`} /> :
      <div className="row">
          <div className="col-9">
              <div className="card mb-4">
                  <div className="card-header pb-0">
                      <h6>{t('SMTP Setup')}</h6>
                  </div>
                  <div className="card-body">
                      <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                        <input type="hidden" defaultValue="PUT" {...register("_method")} />
                          <div className="row g-3">

                              <div className="col-md-6">
                                  <div className="form-group mb-4">
                                      <label>
                                          {t('Sender Name')} *
                                      </label>
                                      <br />
                                      <input type="text" className="form-control" placeholder="z.B. Sender Name" {...register('sender_name',{required:true})} required />
                                      <div className="invalid-feedback">{errors.sender_name && errors.sender_name.message}</div>
                                  </div>
                              </div>
                                <div className="col-md-6 ">
                                    <div className="form-group mb-4">
                                        <label>
                                            {t('Sender Email')} *
                                        </label>
                                        <br />
                                        <input type="text" className="form-control" placeholder="Sender Email" {...register('sender_email',{required:true})} required />
                                        <div className="invalid-feedback">{errors.sender_email && errors.sender_email.message}</div>
                                    </div>
                                </div>
                          </div>

                          <div className="row g-3">
                                  <div className="col-md-6">
                                      <div className="form-group mb-4">
                                          <label>
                                              {t('Host')} *
                                          </label>
                                          <br />
                                          <input type="text" placeholder="eg: smtp.gmail.com" className="form-control" {...register('smtp_host',{required:true})} required />
                                          <div className="invalid-feedback">{errors.smtp_host && errors.smtp_host.message}</div>
                                      </div>
                                  </div>

                                  <div className="col-md-6 ">
                                      <div className="form-group mb-4">
                                          <label>
                                              {t('Port')} *
                                          </label>
                                          <input type="text" className="form-control" placeholder="eg: 465" {...register('smtp_port',{required:true})} required />
                                          <div className="invalid-feedback">{errors.smtp_port && errors.smtp_port.message}</div>
                                      </div>
                                  </div>
                          </div>

                          <div className="row g-3">
                                  <div className="col-md-6">
                                      <div className="form-group mb-4">
                                          <label>
                                              {t('User')} *
                                          </label>
                                          <br />
                                          <input type="text" placeholder="eg: user@gmal.com" className="form-control" {...register('smtp_user',{required:true})} required />
                                          <div className="invalid-feedback">{errors.smtp_user && errors.smtp_user.message}</div>
                                      </div>
                                  </div>

                                  <div className="col-md-6 mb-4">
                                      <label>
                                          {t('Password')} *
                                      </label>
                                      <input type="text" className="form-control" placeholder="password" {...register('smtp_password',{required:true})} required />
                                      <div className="invalid-feedback">{errors.smtp_password && errors.smtp_password.message}</div>
                                  </div>
                          </div>
                          <div className="row g-3">
                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <label>
                                            {t('Encryption')} *
                                        </label>
                                        <br />
                                        <select className="form-control" {...register('encryption',{required:true})} required>
                                            <option value="default">Default</option>
                                            <option value="ssl">SSL</option>
                                            <option value="tls">TLS</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.encryption && errors.encryption.message}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                  <label>{t('Status')}</label>
                                  <select className="form-control" {...register("status")}>
                                      <option value="Active">Active</option>
                                      <option value="Inactive">Inactive</option>
                                  </select>
                                </div>
                          </div>
                          <div className="row g-3">
                                <div className="col-md-6">
                                  <label>{t('Client')}</label>
                                  <select className="form-control text-capitalize" {...register("company",{required:true})} required >
                                    {/* {
                                        companies && companies?.map((company, index) =>(
                                            <option key={index} value={company}>{company}</option>
                                        ))
                                    } */}
                                    <option value="execute">Execute</option>
                                    <option value="qontrolo">Qontrolo</option>
                                    <option value="solacloud">Solacloud</option>
                                  </select>
                                </div>
                          </div>

                          <div className="row mt-3">
                              <div className="col-md-5">{receivedError && <SoftAlert color="error" dismissible> {receivedError} </SoftAlert> }</div>
                              <div className="col-md-12 mt-3">
                                  {!saving && (
                                      <button type="submit" className="btn btn-primary">
                                          {t('Save')}
                                      </button>
                                  )}
                                  {saving && (
                                      <button type="submit" className="btn btn-disabled" disabled>
                                          {t('Saving ...')}
                                      </button>
                                  )}
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
          {renderSuccessSB}
      </div>;
}
export default SmtpEdit