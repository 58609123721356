import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import DepartmentManageModal from "./DepartmentManageModal";
import DesignationManageModal from "./DesignationManageModal";
import ImagePopup from "./ImagePopup";
import SoftSnackbar from "components/SoftSnackbar";
import { Company } from "context/DashboardContext/DashboardContext";
import { useAlert } from "context/AlertContext/AlertContext";
import Alert from "helpers/Alert/Alert";
import callFetch from "helpers/callFetch";

function CustomerAdd({ onImageSelect, nameChange, showData, title }) {
    const {setColor,setIcon,setTitle,setMessage} = useAlert();
    const { dashboardlogo } = Company();
    const { t } = useTranslation();
    const params = useParams();
    const [roles, setRoles] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isModalVisible, setModalVisible] = useState(false);
    const [files,setFile] = useState();
    const [image, setImage] = useState();
    const [employeeId, setEmployeeId] = useState('');
    const [receivedError, setReceivedError] = useState(null);
    const [fetchs, setFetch] = useState(window.location.href.split("/")[6] !== "create")
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    //image crop modal
    const closeModal = () => {
        setValue('photo','')
        setModalVisible(false)
    }
    const WithoutCrop = () => {
        if(files){
            const reader = new FileReader();
            reader.onload = () =>{
                onImageSelect(URL.createObjectURL(files))
                setImage(files)
            }
            reader.readAsDataURL(files);
        }
        setModalVisible(false);
    }
    const openModal = () => {
      setModalVisible(true);
    };

    const croppedPhoto = async (photo) => {
        try {
            const response = await fetch(photo.url);
            const blob = await response.blob();
            // Create a File object from the Blob
            const file = new File([blob], 'photo.jpg', {
              type: 'image/jpeg', // Set the appropriate MIME type
              lastModified: new Date().getTime(), // Set the current timestamp
            });
    
            onImageSelect(URL.createObjectURL(file))
            setImage(file)
            setModalVisible(false);
          } catch (error) {
            console.error('Error fetching and creating file:', error);
          }
    }
  
    const handleImageChange = (event) =>{
        const file = event.target.files[0]
        setFile(file)
        if(file){
            setSelectedImage(URL.createObjectURL(file));
            const reader = new FileReader();
            reader.onload = () =>{
                setSelectedImage(URL.createObjectURL(file));
                openModal();
            }
            reader.readAsDataURL(file);
        }
    }
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name) {
          let selectedOptionText = '---';
          
          if (event.target.nodeName === 'SELECT') {
            const selectedOption = event.target.options[event.target.selectedIndex];
            selectedOptionText = selectedOption ? selectedOption.text : '';
          } else if (event.target.nodeName === 'INPUT' && event.target.type === 'text') {
            selectedOptionText = `${value}`; // Customize this value as needed
          }
          nameChange({
            ...showData,
            [name]: value,
            [name]: selectedOptionText,
          });
        }
      };

    useEffect(() => {
        if(params?.id && window.location.href.split("/")[6] !== "create"){
            callFetch("customers/create", "GET", []).then((res) => {
                setRoles(res?.data?.roles);
            });
            callFetch("customers/" + params.id + `/edit?company=${dashboardlogo}`, "GET", []).then((res) => {
                setValue('client_number', res?.data?.client_number);
                setValue('company_name', res?.data?.company_name);
                setValue('company_email', res?.data?.company_email);
                setValue('phone', res?.data?.phone);
                setValue('mobile_phone', res?.data?.mobile_phone);
                setValue('website', res?.data?.website);
                setValue('country', res?.data?.country);
                setValue('street', res?.data?.street);
                setValue('plz', res?.data?.plz);
                setValue('ort', res?.data?.ort);
            });
        }else{
            if(dashboardlogo === null){
                setColor('warning')
                setIcon('warning')
                setTitle('Warning')
                setMessage('Please select a company')
            }
            callFetch("customers/create", "GET", []).then((res) => {
                setValue('client_number', res.data.id);
                setRoles(res?.data?.roles);
            });
        }
        
    }, [refresh,params?.id,fetchs]);
    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
          color="success"
          icon="check"
          title="Success"
          content="Employee Erfolgreich gespeichert"
          dateTime="Just Now"
          open={successSB}
          onClose={closeSuccessSB}
          close={closeSuccessSB}
          bgWhite
        />
      );


    const onSubmit = (formData) => {
        if(image !== undefined) {
            formData.photo = image;
        }
        formData.company = dashboardlogo
        setSaving(true);
        callFetch(params?.id ? `customers/${params?.id}`:"customers", "POST", formData, setError).then((res) => {
            setSaving(false);
            if(receivedError == null && res.message === 'success' && params?.id){
                openSuccessSB();
            }
            if(!params?.id){
                setEmployeeId(res?.customer_id);
            }
            setEmployeeId(res?.customer_id);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to={params?.id ? '/customer-managment/customer':'/customer-managment/customer/'+employeeId+'/edit'} /> :
        <div className="row">
            <div className="col-12">
                <div className="card mb-4">
                    <div className="card-header pb-0">
                        <h6>{t(`${title ? title:'Kontakdaten'}`)}</h6>
                    </div>
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            {
                                params?.id && <input type="hidden" defaultValue="PUT" {...register("_method")} />
                            }
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label>
                                        {t('Client Nr.')} *
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('eg. 123')}
                                        {...register("client_number", {
                                            required: true,
                                        })}
                                        required
                                        readOnly
                                    />
                                    <div className="invalid-feedback">{errors.employee_identity_number && errors.employee_identity_number.message}</div>
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        {t('Company Name')} *
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('eg. Jhon Doe')}
                                        {...register("company_name", {
                                            required: true,
                                        })}
                                        required
                                        onChange={handleInputChange}
                                    />
                                    <div className="invalid-feedback">{errors.company_name && errors.company_name.message}</div>
                                </div>
                            </div>
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label>
                                        {t('Company Email')} *
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control mb-4"
                                        placeholder={t('eg. email@mail.com')}
                                        {...register("company_email", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                                </div>
                                <div className="col-md-6">
                                    <label>{t('Profile Picture')}</label>
                                    <input type="file" className="form-control mb-4" {...register("photo")} onChange={handleImageChange} />
                                    <div className="invalid-feedback">{errors.photo && errors.photo.message}</div>
                                </div>
                            </div>
                            <div className="row g-3">
                                <div className="col-md-4">
                                    <label>
                                        {t('Website')} *
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder="https://www.example.com"
                                        {...register("website", {
                                            required: params?.id ? false: true,
                                        })}
                                        required={params?.id ? false: true}
                                    />
                                    <div className="invalid-feedback">{errors.website && errors.website.message}</div>
                                </div>
                                <div className="col-md-4">
                                    <label>
                                        {t('Phone')} *
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('phone')}
                                        {...register("phone", {
                                            required: params?.id ? false: true,
                                        })}
                                        required={params?.id ? false: true}
                                    />
                                    <div className="invalid-feedback">{errors.phone && errors.phone.message}</div>
                                </div>
                                <div className="col-md-4">
                                    <label>
                                        {t('Mobile Phone')}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('eg. 98765432')}
                                        {...register("mobile_phone")} />
                                    <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                </div>
                            </div>
                            {/* <div className="row g-3">
                                <div className="col-md-6">
                                    <label>
                                        {t('Company')}
                                    </label>
                                    <select className="form-control mb-4" {...register("company",{required:true})} required>
                                        <option>---</option>
                                        <option value="execute">{t('Execute')}</option>
                                        <option value="qontrolo">{t('Qontrolo')}</option>
                                        <option value="solacloud">{t('Solacloud')}</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.company && errors.company.message}</div>
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        {t('Status')}
                                    </label>
                                    <select className="form-control mb-4" {...register("status",{required:true})} required>
                                        <option value="active">{t('Active')}</option>
                                        <option value="inactive">{t('Inactive')}</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.company && errors.company.message}</div>
                                </div>
                            </div> */}
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label>
                                        {t('Country')}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('country')}
                                        {...register("country")} />
                                    <div className="invalid-feedback">{errors.country && errors.country.message}</div>
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        {t('Street')}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('street')}
                                        {...register("street")} />
                                    <div className="invalid-feedback">{errors.street && errors.street.message}</div>
                                </div>

                            </div>

                            <div className="row g-3">
                                    <div className="col-md-6">
                                        <label>
                                            {t('PLZ')}
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('plz')}
                                            {...register("plz")} />
                                        <div className="invalid-feedback">{errors.plz && errors.plz.message}</div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>
                                            {t('ORT')}
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('ort')}
                                            {...register("ort")} />
                                        <div className="invalid-feedback">{errors.ort && errors.ort.message}</div>
                                    </div>
                            </div>
                           
                            <div className="col-12 mb-4 text-end">
                                {!saving && (
                                    <button type="submit" className="btn btn-primary">
                                        {t('Save')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled" disabled>
                                        {t('Saving ...')}
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>

                {/* Department, Designation Modal Start */}
                <DesignationManageModal refreshParent={() => setRefresh(refresh + 1)} />
                <DepartmentManageModal refreshParent={() => setRefresh(refresh + 1)} />
                {/* Department, Designation Modal End */}
                <ImagePopup showModal={isModalVisible} WithoutCrop={WithoutCrop} openModal={closeModal} photoURL={selectedImage} filePhoto={files} croppedPhoto={croppedPhoto} />
                { submitSuccess && <Alert/>}
            </div>
        </div>;
}

export default CustomerAdd;
