import { Grid, Popover } from '@mui/material'
import SoftTypography from 'components/SoftTypography'
import React from 'react'
import "./styles.css"
import Car from "../../../../assets/images/car.svg"
const RoutenCard = ({data,key}) => {

  return (
    <>
      
        <Grid>
            <Grid width="14rem" mt={1}  display="flex" mx={.2}>
            <div className="" style={{ width:"13.65rem" }}>
                  <div className="end-0 stan z-index-3 d-flex align-items-center justify-content-center px-1 py-1" style={{ background:"#CB0C9F" }}>
                      <div className="d-flex align-items-center justify-content-center">
                        <img src={Car} alt='car' className="me-2"/>
                        <SoftTypography fontSize="0.625rem" variant="caption" color="white" fontWeight="medium">72 km | 1:20 h</SoftTypography>
                      </div>
                  </div>
                  <div className="rounded-3" style={{ background:"rgba(206, 237, 255, 0.87)" }}>
                      <div className="p-2 m-0">
                          <SoftTypography className="" fontSize="10px" style={{ color:"#000" }} >{data?.address} - {data?.telephone}</SoftTypography>
                          <SoftTypography className="" fontSize="10px" style={{ color:"#000" }} fontWeight="medium">{data?.date} - {data?.start} - {data?.end} Uhr</SoftTypography>
                      </div>
                  </div>
                </div>
            </Grid>
            
        </Grid>
        
    </>
  )
}

export default RoutenCard