/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import CustomerAdd from "../../CustomerAdd";
import LeadBaseCustomerCreate from "../../LeadBaseCustomerCreate";

function BasicInfo({onImageSelect,nameChangeValue,showData}) {
  const [leadBaseCustomer, setLeadBaseCustomer] = useState(false);
  const handleImageChane = (imageUrl) =>{
    onImageSelect(imageUrl)
  }

  const handleNameChange = (name) =>{
    nameChangeValue(name);
  }
  return (
    <>
      {
        window.location.href.split("/")[6] === "create" ? 
        <LeadBaseCustomerCreate onImageSelect={handleImageChane} nameChange={handleNameChange} showData={showData}/> 
        : 
        <CustomerAdd onImageSelect={handleImageChane} nameChange={handleNameChange} showData={showData}/>
      }
    </>
      
  );
}

export default BasicInfo;
