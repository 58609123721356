import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Soft UI Context Provider
import { SoftUIControllerProvider } from "context";

import './i18n';
import { DashboardContextProvider } from "context/DashboardContext/DashboardContext";
import { AlertContextProvider } from "context/AlertContext/AlertContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <SoftUIControllerProvider>
      <DashboardContextProvider>
        <AlertContextProvider>
          <App />
        </AlertContextProvider>
      </DashboardContextProvider>
    </SoftUIControllerProvider>
  </BrowserRouter>
);

