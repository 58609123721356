import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import flatpickr from "flatpickr";
import SoftAlert from "components/SoftAlert";
import SoftSnackbar from "components/SoftSnackbar";
import { Company } from "context/DashboardContext/DashboardContext";
import Attachment from "./components/Attachment";

const LieferantenEdit = () => {
    const { dashboardlogo } = Company();
    const { t } = useTranslation();
    const params = useParams();
    const [data,setData] = useState();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [receivedError, setReceivedError] = useState(null);
    const [selectedFile, setSelectedFile] = useState([]);
    const [finalSelectedFile, setFinalSelectedFile] = useState();
    const [removeFile, setRemoveFile] = useState([]);
    const [updateFile , setUpdateFile] = useState([]);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();
    


    useEffect(() => { }, [refresh2]);

    const handleFileChange = (e) => {
        const file = e.target.files[0]
        setSelectedFile([...selectedFile, file])

    };

    useEffect(() => { }, [refresh2]);
    function TruncateText({ text, maxLength }) {
        if (text.length <= maxLength) {
        return <span>{text}</span>;
        }
    
        const truncatedText = text.slice(0, maxLength) + '...';
    
        return <span title={text}>{truncatedText}</span>;
    }
    const handleRemoveFile = (index) => {
        const updateFile = selectedFile.filter((file , id) => id !== index)
        setSelectedFile(updateFile);
    };

    const handleFinalUpdate = (index) => {
        const updateFile = finalSelectedFile.filter((file , id) => id !== index);
        setFinalSelectedFile(updateFile);
        const finalRemoveFile = finalSelectedFile.filter((file, id) => id === index);
        setRemoveFile([...removeFile,finalRemoveFile[0]])
    };

    useEffect(()=>{
        if(selectedFile){
            const updatedFileList = new DataTransfer();
            selectedFile.forEach((file) => {
                updatedFileList.items.add(file);
            });
            //console.log(updatedFileList.files)
            setValue('attachment',updatedFileList.files)
            //setFinalSelectedFile(updatedFileList.files);
        }
    },[selectedFile])

    useEffect(() => {
        flatpickr(".flat-pickr");
        callFetch("partners/" +params?.id+ "/edit?company="+dashboardlogo, "GET", []).then((res) => {
            setData(res.data?.partner);
            for (let [key, value] of Object.entries(res.data?.partner)) {
                setValue(key, (value == null || value == 'null' ? "" : value ));
            }
            setValue("partner_number","L-"+res.data?.partner?.partner_number)
            const attachment = JSON.parse(res.data?.partner?.attachment)
            setFinalSelectedFile(attachment)
            setRefresh(refresh + 1)
        });
    }, []);




    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
          color="success"
          icon="check"
          title="Success"
          content="Lieferanten successfully Updated"
          dateTime="Just Now"
          open={successSB}
          onClose={closeSuccessSB}
          close={closeSuccessSB}
          bgWhite
        />
      );

    const onSubmit = (formData) => {
         setSaving(true);
        formData.updateFile = JSON.stringify(finalSelectedFile)
        formData.removeFile = JSON.stringify(removeFile)
        callFetch("partners/" + params?.id, "POST", formData, setError).then((res) => {
            if(receivedError == null && res.message === "success"){
                openSuccessSB();
            } 
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };





return submitSuccess ? <Navigate to={`/purchasing/lieferanten-partner`} /> :
    <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <input type="hidden" defaultValue="PUT" {...register("_method")} />
        
        <div className="row">
            <div className="col-9">
                <div className="card mb-4">
                    <div className="card-header pb-0">
                        <h6>{t('Add Partner Company')}</h6>
                    </div>
                    <div className="card-body">
                            <div className="row g-3">
                                <div className="col-md-6 mb-4">
                                    <label>{t('Partner Number.')} *</label>
                                     <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('L-100001')}
                                        {...register("partner_number", {
                                            required: true,
                                        })}
                                        readOnly
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.partner_number && errors.partner_number.message}</div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <label>
                                            {t('Company Name')} *
                                        </label>
                                        <br />
                                        <input type="text" className="form-control" placeholder="z.B. Company ABCD" {...register('company_name',{required:true})} required />
                                        <div className="invalid-feedback">{errors.company_name && errors.company_name.message}</div>
                                    </div>
                                </div>

                            </div>

                            <div className="row g-3">
                                    <div className="col-md-6">
                                        <div className="form-group mb-4">
                                            <label>
                                                {t('E-Mail')} *
                                            </label>
                                            <br />
                                            <input type="text" placeholder="email@mail.com" className="form-control" {...register('email',{required:true})} required />
                                            <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 ">
                                        <div className="form-group mb-4">
                                            <label>
                                                {t('Logo')} *
                                            </label>
                                            <input type="file" className="form-control" placeholder="logo" {...register('logo')} />
                                            <div className="invalid-feedback">{errors.logo && errors.logo.message}</div>
                                        </div>
                                    </div>
                            </div>

                            <div className="row g-3">
                                    <div className="col-md-6 mb-4">
                                        <label>
                                            {t('Phone')} *
                                        </label>
                                        <input type="number" className="form-control" placeholder="phone" {...register('phone',{required:true})} required />
                                        <div className="invalid-feedback">{errors.phone && errors.phone.message}</div>
                                    </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <label>{t('Address')}</label>
                                    <textarea className="form-control" rows="3"
                                        defaultValue=""
                                        placeholder="eg. Address"
                                        {...register("description")}></textarea>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-5">{receivedError && <SoftAlert color="error" dismissible> {receivedError} </SoftAlert> }</div>
                                <div className="col-md-12 mt-3">
                                    {!saving && (
                                        <button type="submit" className="btn btn-primary">
                                            {t('Save')}
                                        </button>
                                    )}
                                    {saving && (
                                        <button type="submit" className="btn btn-disabled" disabled>
                                            {t('Saving ...')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        
                    </div>
                </div>
            </div>
            <div className="col-3">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group mb-4">
                                <label>
                                    {t('Attachment')} *
                                </label>
                                <br />
                                <input type="file" placeholder="attachment" className="form-control" onChange={handleFileChange} />
                                <div className="invalid-feedback">{errors.attachment && errors.attachment.message}</div>
                            </div>
                            <div className="">
                                {
                                    finalSelectedFile && finalSelectedFile?.map((attachment ,index) =>(
                                        <div className="d-flex align-items-center bg-light my-2 p-1 border-radius-md mt-0" key={index}>
                                        <p className="p-0 m-0 w-90 truncate text-sm"><TruncateText text={attachment.name} maxLength={41} /></p>
                                        &nbsp;
                                        <i style={{ color:"#005498"}} className="fa-solid fa-download cursor-pointer" onClick={() => {
                                         window.open(process.env.REACT_APP_STORAGE_URL + attachment?.link, '_blank') 
                                        }}></i>
                                        &nbsp;
                                        <i className="fa-solid fa-circle-xmark text-danger cursor-pointer ms-2" onClick={() => handleFinalUpdate(index)}></i>
                                        </div>
                                    ))
                                }
                                {
                                    selectedFile && selectedFile.map((file, index) =>(
                                        <div className="d-flex align-items-center bg-light my-2 p-1 border-radius-md mt-0" key={index}>
                                        <p className="p-0 m-0 w-90 truncate text-sm"><TruncateText text={file.name} maxLength={41} /></p>
                                        &nbsp;
                                        <i className="fa-solid fa-circle-xmark text-danger cursor-pointer ms-2" onClick={() => handleRemoveFile(index)}></i>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {renderSuccessSB}
        </div>
    </form>
}

export default LieferantenEdit