import React, { useState } from 'react'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import RoutenplanerModal from './RoutenplanerModal'
const Routenplaner = () => {
    const {t} = useTranslation()
    const [refresh, setRefresh] = useState(0);
  return (
    <>
        <div className="d-sm-flex justify-content-between">
            <div className="relative-h">
                {Cookies.get('permissions').indexOf("employee-create") !== -1 ? (
                    <button className="btn btn-icon btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#RoutenModal">
                        {t('Routes')}
                    </button>
                ) : <></>}
            </div>    
        </div>
        <RoutenplanerModal refreshParent={() => setRefresh(refresh + 1)} />
    </>
  )
}

export default Routenplaner