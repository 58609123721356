import { Suspense, useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Soft UI Dashboard PRO React routes
//import routes from "routes";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";


import 'react-toastify/dist/ReactToastify.css';
import ApplicationSettings from "./pages/ApplicationSettings";
import PrivateOutlet from "./components/PrivateOutlet";
import Signin from "./pages/Signin";
/*
import SmtpIndex from './pages/user_settings/smtp/SmtpIndex';
import SmtpCreate from './pages/user_settings/smtp/SmtpCreate';
import SmtpEdit from './pages/user_settings/smtp/SmtpEdit';

/*
import FormCreate from './pages/form/FormCreate';
import FormIndex from './pages/form/FormIndex';
import FormEdit from './pages/form/FormEdit';
import FormBuilder from './pages/form/FormBuilder';
import FormShow from './pages/form/FormShow';
import CustomerIndex from './pages/customer_management/customer/CustomerIndex';
import CustomerCreate from './pages/customer_management/customer/CustomerCreate';
*/

import Dashboard from './pages/dashboard/Index'; 

import CallcenterCreate from './pages/callcenter/Create';
import CallcenterEdit from './pages/callcenter/Edit';
import CallcenterIndex from './pages/callcenter/Index';



import Settings from './pages/settings/Index';
import Routenplaner from "pages/hr/employee/Routenplaner/Routenplaner";


import CustomerIndex from "pages/customer/CustomerIndex";
import CustomerCreate from "pages/customer/CustomerCreate";

import InvoiceIndex from './pages/finance/Invoice/InvoiceIndex';
import InvoiceCreate from './pages/finance/Invoice/InvoiceCreate';
import AddEinkauf from "pages/finance/Einkauf/AddEinkauf";
import EinkaufIndex from "pages/finance/Einkauf/EinkaufIndex";
import EditEinkauf from "pages/finance/Einkauf/EditEinkauf";
import IncomingInvoiceIndex from "pages/finance/EngangsInvoice/IncomingInvoiceIndex";
import IncomingInviceCreate from "pages/finance/EngangsInvoice/IncomingInvoiceCreate";
import IncomingInvoiceEdit from "pages/finance/EngangsInvoice/IncomingInvoiceEdit";
import OpenInvoiceIndex from "pages/finance/OpenInvoice/OpenInvoiceIndex";


import ProductCreate from './pages/product/Create';

import ModuleIndex from './pages/module/Index';
import ModuleCreate from './pages/module/Create';
import ModuleEdit from './pages/module/Edit';

import SpeicherIndex from './pages/speicher/Index';
import SpeicherCreate from './pages/speicher/Create';
import SpeicherEdit from './pages/speicher/Edit';

import WechselrichterIndex from './pages/wechselrichter/Index';
import WechselrichterCreate from './pages/wechselrichter/Create';
import WechselrichterEdit from './pages/wechselrichter/Edit';

import ArtikelIndex from "pages/finance/Artikel/ArtikelIndex";
import ArtikelCreate from "pages/finance/Artikel/ArtikelCreate";
import ArtikelEdit from "pages/finance/Artikel/ArtikelEdit";
import BundlePreisIndex from "pages/finance/BundlePreis/BundlePreisIndex";
import BundlePresiCreate from "pages/finance/BundlePreis/BundlePresiCreate";
import BundlePreisEdit from "pages/finance/BundlePreis/BundlePreisEdit";

import EmployeeCreate from './pages/hr/employee/EmployeeCreate';
import EmployeeIndex from './pages/hr/employee/EmployeeIndex';
import EmployeeEdit from "pages/hr/employee/EmployeeEdit";
import QuotationIndex from "pages/Salse-Distribution/Quotations/QuotationIndex";
import QuotationCreate from "pages/Salse-Distribution/Quotations/QuotationCreate";
import QuotationEdit from "pages/Salse-Distribution/Quotations/QuotationEdit";
import OrderIndex from "pages/Salse-Distribution/Orders/OrderIndex";
import OrderCreate from "pages/Salse-Distribution/Orders/OrderCreate";
import OrderEdit from "pages/Salse-Distribution/Orders/OrderEdit";
import QuotationByOrderCreate from "pages/Salse-Distribution/Orders/QuotationByOrderCreate";
import OrderByInvoiceCreate from "pages/finance/Invoice/OrderByInvoiceCreate";
import InvoiceEdit from "pages/finance/Invoice/InvoiceEdit";
import LeadIndex from "pages/Lead-Managment/LeadIndex";
import LieferantenIndex from "pages/Procurement/Lieferanten/LieferantenIndex";
import LieferantenEdit from "pages/Procurement/Lieferanten/LieferantenEdit";
import LieferantenDetails from "pages/Procurement/Lieferanten/LieferantenDetails";
import LieferantenCreate from "pages/Procurement/Lieferanten/LieferantenCreate";
import CustomerEdit from "pages/customer/CustomerEdit";
import LeadBaseCustomer from "pages/customer/LeadBaseCustomer";
import CustomerDetails from "pages/customer/CustomerDeatils";
import RoleIndex from "pages/settings/RolePermissions/RoleIndex";
import RoleBasePermissions from "pages/settings/RolePermissions/RoleBasePermissions";
import RoleCretae from "pages/settings/RolePermissions/RoleCretae";
import RoleEidt from "pages/settings/RolePermissions/RoleEidt";
import ProfileIndex from "pages/settings/Profile/ProfileIndex";
import ProfileCreate from "pages/settings/Profile/ProfileCreate";
import ProfileEdit from "pages/settings/Profile/ProfileEdit";
import AddressProfileIndex from "pages/settings/AddressProfile/AddressProfileIndex";
import AddressProfileCreate from "pages/settings/AddressProfile/AddressProfileCreate";
import AddressProfileEdit from "pages/settings/AddressProfile/AddressProfileEdit";
import SmtpIndex from "pages/settings/Smtp/SmtpIndex";
import SmtpCreate from "pages/settings/Smtp/SmtpCreate";
import SmtpEdit from "pages/settings/Smtp/SmtpEdit";

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

    const configsButton = (
        <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="3.5rem"
            height="3.5rem"
            bgColor="white"
            shadow="sm"
            borderRadius="50%"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            color="dark"
            sx={{ cursor: "pointer" }}
            onClick={handleConfiguratorOpen}
        >
        <Icon fontSize="default" color="inherit">
            settings
        </Icon>
        </SoftBox>
    );

    return (
        <>
        {/* <CacheProvider value={rtlCache}> */}
            <ThemeProvider theme={theme}>
                <Routes>
                    <Route path="/" element={<Signin />} />
                    <Route path="/*" element={<PrivateOutlet />}>
                        <Route path="application-settings" element={<ApplicationSettings />} />

                        <Route path="dashboard" element={<Dashboard />} /> 
                        <Route path="profile/settings" element={<Settings />} /> 
                        
                        {/* employee route */}
                        <Route path="hr/employee" element={<EmployeeIndex/>} />
                        <Route path="hr/employee/create" element={<EmployeeCreate/>} />
                        <Route path="hr/employee/:id/edit" element={<EmployeeEdit/>} />

                        <Route path="customer-managment/customer" element={<CustomerIndex/>} />
                        <Route path="customer-managment/customer/create" element={<CustomerCreate />} />
                        <Route path="customer-managment/customer/:id/edit" element={<CustomerEdit />} />
                        <Route path="customer-managment/customer/:id/create" element={<LeadBaseCustomer />} />
                        <Route path="customer-managment/customer/:id/details" element={<CustomerDetails/>} />
                        {/* lead managment */}
                        <Route path="customer-managment/lead-managment/leads" element={<LeadIndex/>}/>


                        <Route path="hr/einkauf" element={<EinkaufIndex/>} />
                        <Route path="hr/einkauf/create" element={<AddEinkauf/>} />
                        <Route path="hr/einkauf/:id/edit" element={<EditEinkauf/>} />

                        {/* Lieferanten & Partner */}
                        <Route path="purchasing/lieferanten-partner" element={<LieferantenIndex/>} />
                        <Route path="purchasing/lieferanten-partner/create" element={<LieferantenCreate/>} />
                        <Route path="purchasing/lieferanten-partner/:id/edit" element={<LieferantenEdit/>} />
                        <Route path="purchasing/lieferanten-partner/:id/details" element={<LieferantenDetails/>} />

                        

                        <Route path="hr/open-invoice" element={<OpenInvoiceIndex/>} />
                        {/** Routenplaner */}
                        <Route path="hr/routenplaner" element={<Routenplaner/>}/>


                        {/* lead managment  */}
                        <Route path="marketing-sales/quotations" element={<QuotationIndex/>} />
                        <Route path="marketing-sales/quotations/create" element={<QuotationCreate />} />
                        <Route path="marketing-sales/quotations/:id/edit" element={<QuotationEdit />} />

                        <Route path="marketing-sales/orders" element={<OrderIndex/>} />
                        <Route path="marketing-sales/orders/create" element={<OrderCreate />} />
                        <Route path="marketing-sales/orders/:id/edit" element={<OrderEdit />} />
                        <Route path="marketing-sales/orders/:id/create" element={<QuotationByOrderCreate />} />

                        {/* <Route path="sales-distribution/orders" element={<OrdersIndex />} />
                        <Route path="sales-distribution/order/:id/edit" element={<OrderEdit />} /> */}

                        <Route path="finance/invoices" element={<InvoiceIndex />} />
                        <Route path="finance/invoices/create" element={<InvoiceCreate />} />
                        <Route path="finance/invoices/:id/edit" element={<InvoiceEdit />} />
                        <Route path="finance/invoices/:id/create" element={<OrderByInvoiceCreate />} />
                        <Route path="finance/einkauf/create" element={<AddEinkauf/>} />

                        {/* incomming invoice */}
                        <Route path="finance/incoming-invoices" element={<IncomingInvoiceIndex/>} />
                        <Route path="finance/incoming-invoices/create" element={<IncomingInviceCreate/>} />
                        <Route path="finance/incoming-invoices/:id/edit" element={<IncomingInvoiceEdit/>} />

                        <Route path="call-center/index" element={<CallcenterIndex />} />
                        <Route path="call-center/create" element={<CallcenterCreate />} />
                        <Route path="call-center/:id/edit" element={<CallcenterEdit />} />

                        <Route path="supply-chain/products/create" element={<ProductCreate />} />
                        <Route path="supply-chain/products/module" element={<ModuleIndex />} />
                        <Route path="supply-chain/products/module/create" element={<ModuleCreate />} />
                        <Route path="supply-chain/products/module/:id/edit" element={<ModuleEdit />} />
                        
                        <Route path="supply-chain/products/speicher" element={<SpeicherIndex />} />
                        <Route path="supply-chain/products/speicher/create" element={<SpeicherCreate />} />
                        <Route path="supply-chain/products/speicher/:id/edit" element={<SpeicherEdit />} />

                        <Route path="supply-chain/products/wechselrichter" element={<WechselrichterIndex />} />
                        <Route path="supply-chain/products/wechselrichter/create" element={<WechselrichterCreate />} />
                        <Route path="supply-chain/products/wechselrichter/:id/edit" element={<WechselrichterEdit />} />

                        <Route path="finance/artikels" element={<ArtikelIndex/>} />
                        <Route path="finance/artikels/create" element={<ArtikelCreate/>} />
                        <Route path="finance/artikels/:id/edit" element={<ArtikelEdit/>} />

                        <Route path="finance/bundle-preis" element={<BundlePreisIndex/>} />
                        <Route path="finance/bundle-preis/create" element={<BundlePresiCreate/>} />
                        <Route path="finance/bundle-preis/:id/edit" element={<BundlePreisEdit/>} />
                        
                        
                        {/* <Route path="user-settings/smtp" element={<SmtpIndex />} />
                        <Route path="user-settings/smtp/create" element={<SmtpCreate />} />
                        <Route path="user-settings/smtp/:id/edit" element={<SmtpEdit />} /> */}
                        <Route path="settings/roles" element={<RoleIndex/>} />
                        <Route path="settings/roles/create" element={<RoleCretae/>}/>
                        <Route path="settings/roles/:id/edit" element={<RoleEidt/>} />

                        {/* profile route */}
                        <Route path="settings/profile" element={<ProfileIndex/>} />
                        <Route path="settings/profile/create" element={<ProfileCreate/>} />
                        <Route path="settings/profile/:id/edit" element={<ProfileEdit/>} />

                        {/* address-profile route */}
                        <Route path="settings/address-profile" element={<AddressProfileIndex/>} />
                        <Route path="settings/address-profile/create" element={<AddressProfileCreate/>} />
                        <Route path="settings/address-profile/:id/edit" element={<AddressProfileEdit/>} />

                        <Route path="settings/smtp" element={<SmtpIndex/>} />
                        <Route path="settings/smtp/create" element={<SmtpCreate/>} />
                        <Route path="settings/smtp/:id/edit" element={<SmtpEdit/>} />

                       
                    </Route>
                </Routes>
            </ThemeProvider>
        {/* </CacheProvider> */}
    </>
    );
}
