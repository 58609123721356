import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import dateFormat from "dateformat";
import flatpickr from "flatpickr";
import SoftAlert from "components/SoftAlert";
import SoftSnackbar from "components/SoftSnackbar";
import { Checkbox } from "@mui/material";
import Select from 'react-select'
import { Company } from "context/DashboardContext/DashboardContext";
const ProfileCreate = () => {
  const { dashboardlogo } = Company();
  const { t } = useTranslation();
  const [currency, setCurrency] = useState('EUR');
  const [data, setData] = useState([]);
  const [saving, setSaving] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [refresh2, setRefresh2] = useState(0);
  const [receivedError, setReceivedError] = useState(null);
  const [einkaufData, setEinkaufData] = useState([])
  const [companies, setCompanies] = useState([])
   const {
      register,
      handleSubmit,
      setError,
      setValue,
      formState: { errors },
  } = useForm();
  


  useEffect(() => { if(refresh2 !== 0){setValue('company',dashboardlogo)} }, [refresh2]);



  useEffect(() => {
      flatpickr(".flat-pickr");
      callFetch("profiles/create", "GET", []).then((res) => {
          //setValue('partner_number', res?.partner_number);
          setCompanies(res?.data)
          //setRefresh(refresh + 1)
          setRefresh2(refresh2 + 1)
      });
  }, [0]);




  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const renderSuccessSB = (
      <SoftSnackbar
        color="success"
        icon="check"
        title="Success"
        content="Invoice successfully created"
        dateTime="Just Now"
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
      />
    );

  const onSubmit = (formData) => {
       setSaving(true);
       //formData.company = dashboardlogo
      callFetch("profiles", "POST", formData, setError).then((res) => {
          if(receivedError == null){
              openSuccessSB();
          } 
          setSaving(false);
          if (!res.ok) return;
          setSubmitSuccess(true);
      }); 

      //logo//company name/ addresse / tel:/ Mail:/ website / Notes
  };

return submitSuccess ? <Navigate to={`/settings/profile`} /> :
      <div className="row">
          <div className="col-9">
              <div className="card mb-4">
                  <div className="card-header pb-0">
                      <h6>{t('Add Profile')}</h6>
                  </div>
                  <div className="card-body">
                      <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                          <div className="row g-3">

                              <div className="col-md-6">
                                  <div className="form-group mb-4">
                                      <label>
                                          {t('Company Name')} *
                                      </label>
                                      <br />
                                      <input type="text" className="form-control" placeholder="z.B. Company ABCD" {...register('company_name',{required:true})} required />
                                      <div className="invalid-feedback">{errors.company_name && errors.company_name.message}</div>
                                  </div>
                              </div>
                                <div className="col-md-6 ">
                                    <div className="form-group mb-4">
                                        <label>
                                            {t('Logo')} *
                                        </label>
                                        <input type="file" className="form-control" placeholder="logo" {...register('logo',{required:true})} required />
                                        <div className="invalid-feedback">{errors.logo && errors.logo.message}</div>
                                    </div>
                                </div>
                          </div>

                          <div className="row g-3">
                                  <div className="col-md-6">
                                      <div className="form-group mb-4">
                                          <label>
                                              {t('E-Mail')} *
                                          </label>
                                          <br />
                                          <input type="text" placeholder="email@mail.com" className="form-control" {...register('email',{required:true})} required />
                                          <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                                      </div>
                                  </div>

                                  <div className="col-md-6 ">
                                      <div className="form-group mb-4">
                                          <label>
                                              {t('Telephone')} *
                                          </label>
                                          <input type="text" className="form-control" placeholder="telephone" {...register('telephone',{required:true})} required />
                                          <div className="invalid-feedback">{errors.telephone && errors.telephone.message}</div>
                                      </div>
                                  </div>
                          </div>

                          <div className="row g-3">
                                  <div className="col-md-6">
                                      <div className="form-group mb-4">
                                          <label>
                                              {t('Website')} *
                                          </label>
                                          <br />
                                          <input type="text" placeholder="https://" className="form-control" {...register('website',{required:true})} required />
                                          <div className="invalid-feedback">{errors.website && errors.website.message}</div>
                                      </div>
                                  </div>

                                  <div className="col-md-6 mb-4">
                                      <label>
                                          {t('Address')} *
                                      </label>
                                      <input type="text" className="form-control" placeholder="house#" {...register('address',{required:true})} required />
                                      <div className="invalid-feedback">{errors.address && errors.address.message}</div>
                                  </div>
                          </div>
                          <div className="row g-3">
                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <label>
                                            {t('Client')} *
                                        </label>
                                        <br />
                                        <select className="form-control" {...register('company',{required:true})} required defaultValue={dashboardlogo}>
                                            <option>----</option>
                                            {
                                                companies && Object.entries(companies)?.map((company,index) =>(
                                                    <option key={index} value={company[1]}>{company[1]}</option>
                                                ))
                                            }
                                        </select>
                                        <div className="invalid-feedback">{errors.website && errors.website.message}</div>
                                    </div>
                                </div>
                          </div>
                          <div className="row mt-3">
                              <div className="col-md-12">
                                  <label>{t('Note')}</label>
                                  <textarea className="form-control" rows="3"
                                      defaultValue=""
                                      placeholder="eg. Note"
                                      {...register("note")}></textarea>
                              </div>
                          </div>

                          <div className="row mt-3">
                              <div className="col-md-5">{receivedError && <SoftAlert color="error" dismissible> {receivedError} </SoftAlert> }</div>
                              <div className="col-md-12 mt-3">
                                  {!saving && (
                                      <button type="submit" className="btn btn-primary">
                                          {t('Save')}
                                      </button>
                                  )}
                                  {saving && (
                                      <button type="submit" className="btn btn-disabled" disabled>
                                          {t('Saving ...')}
                                      </button>
                                  )}
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
          {renderSuccessSB}
      </div>;
}

export default ProfileCreate