import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import dateFormat from "dateformat";
import flatpickr from "flatpickr";
import callFetch from "helpers/callFetch";
import { NumericFormat } from 'react-number-format';
import { Company } from "context/DashboardContext/DashboardContext";
import { useAlert } from "context/AlertContext/AlertContext";
import Alert from "helpers/Alert/Alert";
function QuotationCreate() {
    const { dashboardlogo } = Company();
    const {} = useAlert();
    const { t } = useTranslation();
    const [customerId, setCustomerId] = useState(0);
    const [currency, setCurrency] = useState('EUR');
    const [contactPersons, setContactPersons] = useState([]);
    const [data, setData] = useState([]);
    const [emails, setEmails] = useState([{ name: '', email: '', currentEmail: '' }]);
    const [items, setItems] = useState([{ id: 0, title: '',product:'', quantity: 1, price: 0, currentPrice: 0, total: 0 }]);
    const [itemSummery, setItemSummery] = useState({ items: [], emails: [], subTotal: 0, discountPercentage: 0, discount: 0, taxPercentage: 20, tax: 0, total: 0 });
    const [quotation, setQuotation] = useState(0);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();
    useEffect(() => {
        var subTotal = 0;
        items.map((item) => {
            subTotal += item.total;
        });
        itemSummery.subTotal = subTotal;
        itemSummery.tax = parseFloat((subTotal * (itemSummery.taxPercentage / 100)).toFixed(2));
        itemSummery.discount = parseFloat((subTotal * (itemSummery.discountPercentage / 100)).toFixed(2));
        itemSummery.total = subTotal - itemSummery.discount + itemSummery.tax;
        itemSummery.items = items;
        setItemSummery(itemSummery);
        setRefresh2(refresh2 + 1);
    }, [refresh]);

    useEffect(() => { }, [refresh2]);


    useEffect(() => {
        flatpickr(".flat-pickr");
        callFetch("quotations/create?company="+dashboardlogo, "GET", []).then((res) => {
            setData(res?.data);
            setValue('quotation_number', res?.data?.quotatin_number);
        });
    }, []);

    function deleteProduct() {
        var titems = [];
        items.map((t) => {
            if (!t)
                return;
            titems.push(t);
        });
        setItems(titems);
        setRefresh(refresh + 1);
    }

    function deleteEmail() {
        var temails = [];
        emails.map((e) => {
            if (!e && e !== '')
                return;
            temails.push(e);
        });
        setEmails(temails);
        itemSummery.emails = temails;
        setItemSummery(itemSummery);
        setRefresh2(refresh2 + 1);
    }


    const onSubmit = (formData) => {
        setSaving(true);
        formData.total = itemSummery.total;
        formData.company = dashboardlogo
        formData.product_details = JSON.stringify(itemSummery);
        callFetch("quotations", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setQuotation(res?.data?.quotation_number);
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to={'/marketing-sales/quotations/'+quotation+'/edit'} /> :
            <div className="row">
            <div className="col-9">
                <div className="card mb-4">
                    <div className="card-header pb-0">
                        <h6>{t('Add Quotation')}</h6>
                    </div>
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            <div className="row g-3">
                                <div className="col-md-3">
                                    <label>{t('Quotation Number')} *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('eg: 300001')}
                                        {...register("quotation_number", {
                                            required: true,
                                        })}
                                        readOnly
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.quotation_number && errors.quotation_number.message}</div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>
                                            {t('Client')} *
                                        </label>
                                        <br />
                                        <select
                                            className="form-control"
                                            {...register("client_id", {
                                                required: true,
                                            })}
                                            onChange={(e) => {
                                                let customerId = e.target.value;
                                                setCustomerId(customerId);
                                            }}
                                            required
                                        >
                                            <option value="">--</option>
                                            {data?.customers && data?.customers.map((customer) => (
                                                <option key={customer.id} value={customer.id}>{customer.client_number+" - "+customer.company_name}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">{errors.client_number && errors.client_number.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>
                                            {t('Currency')} *
                                        </label>
                                        <br />
                                        <select
                                            className="form-control"
                                            {...register("currency", {
                                                required: true,
                                            })}
                                            required
                                            onChange={(e)=>setCurrency(e.target.value)}
                                        >
                                            <option value="EUR">EUR (€)</option>
                                            <option value="USD">USD ($)</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.currency && errors.currency.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <label>
                                        {t('Quotation Date')} *
                                    </label>
                                    <input
                                        type="date"
                                        className="form-control mb-4 flat-pickr"
                                        placeholder={t('eg. 2001-03-20')}
                                        defaultValue={dateFormat(new Date(), "yyyy-mm-dd")}
                                        {...register("quotation_date", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.start_date && errors.start_date.message}</div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <label>{t('Description')}</label>
                                    <textarea className="form-control" rows="3"
                                        placeholder="eg. Description"
                                        {...register("description")}></textarea>
                                </div>
                            </div>

                            <div className="mt-3">
                                {items && items.map((item, i) => (
                                    <div key={i}>
                                        {i > 0 && <hr />}
                                        <div className="row mt-2">
                                            <div className="col-md-8">
                                                <label>{t('Product')}</label>
                                                <input type="text" className="form-control" placeholder={t('Select Product')} onChange={(e) => {
                                                      items[i].id = i + 1;
                                                      items[i].product = e.target.value;
                                                      setItems(items);
                                                      setRefresh(refresh + 1);
                                                  }}/>
                                            </div>
                                            <div className="col-md-2">
                                                <label>{t('Qty/Hrs')}</label>
                                                <input type="number" className="form-control" placeholder="eg. 0.00" defaultValue="1" onChange={(e) => {
                                                    let value = 0;
                                                    if (e.target.value.length && !isNaN(e.target.value))
                                                        value = e.target.value;
                                                    items[i].quantity = parseFloat(value);
                                                    items[i].total = items[i].currentPrice * items[i].quantity;
                                                    setItems(items);
                                                    setRefresh(refresh + 1);
                                                }} />
                                            </div>
                                            <div className="col-md-2">
                                                <label>{t('Amount')}</label>
                                                <input type="number" className="form-control d-inline" style={{ width: '84%' }} placeholder="eg. 0.00" value={items[i].currentPrice ? items[i].currentPrice : ''} onChange={(e) => {
                                                        let value = 0;
                                                        if (e.target.value.length && !isNaN(e.target.value))
                                                            value = e.target.value;
                                                        items[i].currentPrice = parseFloat(value);
                                                        items[i].total = items[i].currentPrice * items[i].quantity;
                                                        setItems(items);
                                                        setRefresh(refresh + 1);
                                                }} />
                                                &nbsp;
                                                <i className="fa-solid fa-circle-xmark text-danger" data-key={i} onClick={() => { delete items[i]; deleteProduct(); }}></i>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <p className="btn btn-dark" style={{ cursor: 'pointer' }} onClick={() => { setItems([...items, { id:0, title: '',product:'', quantity: 1, price: 0, currentPrice: 0, total: 0 }]); setRefresh(refresh + 1); }}><i className="fa-solid fa-circle-plus"></i>&nbsp;{t('Add Item')}</p>
                                    </div>
                                </div>

                                <hr />

                                <div className="row">
                          <div className="col-md-6 offset-md-6 border">
                              <div className="row">
                                  <div className="col-md-7 border-end">
                                      <label className="d-block text-end">{t('Sub Total')}</label>
                                      {/* <select {...register('amountPerchantage')} onChange={(e) =>{ itemSummery.amountPerchantage = parseFloat(e.target.value); setItemSummery(itemSummery);
                                          setRefresh(refresh + 1) }} className="form-control mb-2" style={{width: '70%', position: 'relative', left: '68px'}}>
                                          <option value="0">--</option>
                                          <option value="100">100%</option>
                                          <option value="70">70%</option>
                                          <option value="30">30%</option>
                                      </select> */}
                                  </div>
                                  <div className="col-md-5">
                                      <p className="text-end">
                                          <NumericFormat 
                                              value={itemSummery.subTotal}
                                              displayType="text" 
                                              thousandSeparator={"."} 
                                              decimalSeparator=","
                                              decimalScale={2}
                                              fixedDecimalScale
                                              suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                          />
                                          </p>
                                  </div>
                              </div>
                              <div className="row border-top d-none">
                                  <div className="col-md-3 border-end">
                                      <label className="d-block text-end">{t('Discount')}</label>
                                  </div>
                                  <div className="col-md-3 border-end">
                                      <input type="number" className="form-control" value={itemSummery.discountPercentage} onChange={(e) => {
                                          let value = 0;
                                          if (e.target.value.length && !isNaN(e.target.value))
                                              value = e.target.value;
                                          itemSummery.discountPercentage = parseFloat(value);
                                          setItemSummery(itemSummery);
                                          setRefresh(refresh + 1);
                                      }} />
                                  </div>
                                  <div className="col-md-1 border-end">
                                      <p className="text-end">%</p>
                                  </div>
                                  <div className="col-md-5">
                                      <p className="text-end">
                                          <NumericFormat 
                                              value={itemSummery.subTotal}
                                              displayType="text" 
                                              thousandSeparator={"."} 
                                              decimalSeparator=","
                                              decimalScale={2}
                                              fixedDecimalScale
                                              suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                          />
                                      </p>
                                  </div>
                              </div>
                              <div className="row border-top">
                                  <div className="col-md-3 border-end">
                                      <label className="d-block text-end">{t('Tax')}</label>
                                  </div>
                                  <div className="col-md-4 border-end">
                                     <select {...register('tax')} className="form-control" onChange={(e) => {
                                          let value = 19;
                                          if (e.target.value.length && !isNaN(e.target.value))
                                              value = e.target.value;
                                          itemSummery.taxPercentage = parseFloat(value);
                                          setItemSummery(itemSummery);
                                          setRefresh(refresh + 1);
                                      }}>
                                          <option value="19">19%</option>
                                          <option value="0">0%</option> 
                                     </select>
                                  </div>
                                  <div className="col-md-5">
                                      <p className="text-end">
                                          <NumericFormat 
                                              value={itemSummery.tax}
                                              displayType="text" 
                                              thousandSeparator={"."} 
                                              decimalSeparator=","
                                              decimalScale={2}
                                              fixedDecimalScale
                                              suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                          />
                                      </p>
                                  </div>
                              </div>
                              <div className="row bg-gray-200">
                                  <div className="col-md-7">
                                      <label className="d-block text-end">{t('Total')}</label>
                                  </div>
                                  <div className="col-md-5">
                                      <p className="text-end">
                                          <NumericFormat 
                                              value={itemSummery.total}
                                              displayType="text" 
                                              thousandSeparator={"."} 
                                              decimalSeparator=","
                                              decimalScale={2}
                                              fixedDecimalScale
                                              suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                          />
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                            </div>

                           

                            <div className="row mt-3">
                                <div className="col-md-12">
                                    {!saving && (
                                        <button type="submit" className="btn btn-primary">
                                            {t('Save')}
                                        </button>
                                    )}
                                    {saving && (
                                        <button type="submit" className="btn btn-disabled" disabled>
                                            {t('Saving ...')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {submitSuccess && <Alert/>}
        </div>
        ;
}

export default QuotationCreate;
