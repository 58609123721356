import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import { Company } from 'context/DashboardContext/DashboardContext';
import SoftBadge from 'components/SoftBadge';

const SmtpIndexTable = () => {
  const { dashboardlogo } = Company();
  const { t } = useTranslation();
  const [tabledata, setTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [searchKey, setSearchKey] = useState("")

  const tableHeadings = [
      {
          name: t('Client Name'),
          selector: row => (
              <NavLink className=" text-capitalize" to={'/settings/smtp/' + row?.id + '/edit'}>
                  {row?.company}
              </NavLink>
          ),
          sortable: true,
      },
      {
        name: t('Sender Name'),
        selector: row => row?.sender_name,
        sortable: true,
    },
      {
        name: t('Sender Email'),
        selector: row => row?.sender_email,
      },
      {
          name: t('Host'),
          selector: row => row?.smtp_host,
      },
      
      {
        name: t('Port'),
        selector: row => row?.smtp_port,
     },
      {
          name: t('Encryption'),
          selector: row => row?.encryption,
      },
      {
        name: t('Status'),
        selector: row => <SoftBadge
                          variant="contained"
                          color={row.status === "Active" ? "success":"error"}
                          size="xs"
                          badgeContent={row.status}
                          container
                        />,
      },
      
      {
          name: t('Actions'),
          cell: row => (
              <>
                  {Cookies.get('permissions').indexOf("invoice-update") !== 1 ||
                      Cookies.get('permissions').indexOf("invoice-delete") !== 1 ? (
                      <div className="text-center dropstart">
                          <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                              <i className="fa fa-ellipsis-v text-xs"></i>
                          </a>
                          <ul className="dropdown-menu">
                              {Cookies.get('permissions').indexOf("invoice-update") !== 1 ? (
                                  <>
                                      <li>
                                          <NavLink to={'/settings/smtp/' + row?.id + '/edit'} className="dropdown-item">
                                              {t('Edit')}
                                          </NavLink>
                                      </li>
                                      <li><hr className="dropdown-divider" /></li>
                                  </>
                              ) : <></>}
                              {Cookies.get('permissions').indexOf("invoice-delete") !== 1 ? (
                                  <li><a className="dropdown-item text-danger" href="#0" onClick={(e) => deleteAlert(e, 'smtp', row.id+`?company=${dashboardlogo}`, t).then(res => setRefresh(refresh + 1))}>{t('Delete')}</a></li>
                              ) : <></>}
                          </ul>
                      </div>
                  ) : <></>}
              </>
          )
      }
  ];

  useEffect(() => {
      callFetch("smtp?page=" + pageNumber+`&company=${dashboardlogo}`, "GET", []).then((res) => {
          setTableData(res?.data);
      });
  }, [pageNumber, refresh]);

  const handlePageChange = page => {
      setPageNumber(page);
  }

  useEffect(() => {
      if (searchKey.length > 0) {
          callFetch('partners/search/' + searchKey+`?company=${dashboardlogo}`, "GET", []).then((res) => {
              setTableData(res.data)
          })
      } else {
          setRefresh(refresh + 1)
      }
  }, [searchKey])

  function toPages(pages) {
      const results = [];

      for (let i = 1; i <= pages; i++) {
          results.push(i);
      }

      return results;
  }

  // RDT exposes the following internal pagination properties
  const BootyPagination = ({
      onChangePage,
      currentPage
  }) => {
      const handleBackButtonClick = () => {
          onChangePage(currentPage - 1);
      };

      const handleNextButtonClick = () => {
          onChangePage(currentPage + 1);
      };

      const handlePageNumber = (e) => {
          onChangePage(Number(e.target.value));
      };

      const pages = tabledata.last_page;
      const pageItems = toPages(pages);
      const nextDisabled = currentPage === tabledata.last_page;
      const previosDisabled = currentPage === 1;

      return (
          <>
              <br />
              <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
              <nav className="float-md-end me-2">
                  <ul className="pagination">
                      <li className="page-item">
                          <button
                              className="page-link"
                              onClick={handleBackButtonClick}
                              disabled={previosDisabled}
                              aria-disabled={previosDisabled}
                              aria-label="previous page"
                          >
                              &#60;
                          </button>
                      </li>
                      {pageItems.map((page) => {
                          const className =
                              page === currentPage ? "page-item active" : "page-item";

                          return (
                              <li key={page} className={className}>
                                  <button
                                      className="page-link"
                                      onClick={handlePageNumber}
                                      value={page}
                                  >
                                      {page}
                                  </button>
                              </li>
                          );
                      })}
                      <li className="page-item">
                          <button
                              className="page-link"
                              onClick={handleNextButtonClick}
                              disabled={nextDisabled}
                              aria-disabled={nextDisabled}
                              aria-label="next page"
                          >
                              &#62;
                          </button>
                      </li>
                  </ul>
              </nav>
              <div className="clearfix"></div>
          </>
      );
  };

  return <DataTable
      columns={tableHeadings}
      data={tabledata?.data}
      noDataComponent={t('There are no records to display')}
      className='data-table'
      pagination
      highlightOnHover
      paginationComponentOptions={{ noRowsPerPage: true }}
      paginationServer
      paginationTotalRows={tabledata?.total}
      onChangePage={handlePageChange}
      paginationComponent={BootyPagination}
      subHeader
      subHeaderComponent={<input type="text" placeholder={t("Search...")} className='form-control w-sm-50 w-md-25 w-lg-15' value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />}
  />;
}

export default SmtpIndexTable