import { useEffect, useState } from "react";
import callFetch from "helpers/callFetch";
import deleteAlert from "helpers/deleteAlert";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { Divider } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import TaskCard from "./TaskCard";
import dateFormat from "dateformat";
import { useParams } from "react-router-dom";
import { Company } from "context/DashboardContext/DashboardContext";
const NotesModal = (props) => {
    const { dashboardlogo } = Company();
    const params = useParams();
    const { t } = useTranslation();
    const [customerCategories, setCustomerCategories] = useState([]);
    const [saving, setSaving] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [notes, setNotes] = useState([]);
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        reset
    } = useForm();

    let serial = 1;

    useEffect(() => {
        callFetch("note/create", "GET", []).then((res) => {
            setNotes(res?.data);
        });
    }, [refresh]);

    const onSubmit = (formData) => {
        setSaving(true);
        formData.customer_id = params?.id
        formData.company = dashboardlogo
        callFetch("note", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            reset();
            setRefresh(refresh + 1);
            // props.refreshParent();
        });
    };

    function doCategoryUpdate(e, id) {
        let name = e.target.parentNode.parentNode.parentNode.getElementsByClassName('category-input')[0].value;
        callFetch("customer-categories/" + id, "POST", { name: name, _method: 'PUT' }).then((res) => {
            if (!res.ok) return;
            setRefresh(refresh + 1);
            props.refreshParent();
        });
    }

    return ( 
        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <div className="modal fade" id="AddnotesModal" tabIndex={-1} role="dialog" aria-labelledby="NotesModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        {/* <div className="modal-header">
                            <h5 className="modal-title" id="NotesModalLabel">{t('Customer Category')}</h5>
                            <button type="button" className="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div> */}
                        <div className="modal-body">
                            <SoftBox mb={4} sx={{ display:"flex", alignItems:"center"}}>
                                <SoftTypography variant="h5" fontSize="18px" >Notes</SoftTypography>
                                <button type="button" className="btn-close text-dark ms-auto mt-0 pt-0" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </SoftBox>
                            <div className="">
                                <div className="form-group">
                                    <label>
                                        {t('Title')}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('Title')}
                                        {...register("title",{required: true})}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.title && errors.title.message}</div>
                                </div>
                                <div className="form-group">
                                    <label>
                                        {t('Note about Client')}
                                    </label>
                                    <textarea className="form-control" rows={4} {...register("note_about_client",{required: true})} required ></textarea>
                                    <div className="invalid-feedback">{errors.note_about_client && errors.note_about_client.message}</div>
                                </div>
                                
                            </div>
                            <div className="d-flex align-items-center justify-content-end" >
                                {!saving && (
                                    <button type="submit" className="btn btn-primary">
                                        {t('Submit')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled" disabled>
                                        {t('Submited ...')}
                                    </button>
                                )}
                            </div>
                            <Divider/>
                            <div className="mt-4">
                                <SoftBox mb={4}>
                                    <SoftTypography variant="h5" fontSize="18px" >Previous Notes:</SoftTypography>
                                </SoftBox>
                                <div className="row">
                                    <div className="col-12">
                                        <div style={{ height:"16rem", overflow:"auto" }}>
                                            {
                                                notes?.notes && notes?.notes?.map((note,index) =>(
                                                    <div className="mt-2">
                                                        <TaskCard
                                                            notes={true}
                                                            title={note?.title}
                                                            description={note?.note_about_client}
                                                            members={[note.user ? process.env.REACT_APP_STORAGE_URL + note.user?.photo : '/assets/img/placeholder.png']}
                                                            name={note.user.name}
                                                            date={dateFormat(note.created_at, "dd.mm.yyyy")}
                                                        />
                                                    </div>
                                                ))
                                            }
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default NotesModal