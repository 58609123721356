import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useLocation } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';


function EmployeeCreate() {
    const { t } = useTranslation();
    const location = useLocation()
    const [roles, setRoles] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    const handleImageChange = () => {
        
    };


  useEffect(() => {
    callFetch('employees/create', "GET", []).then((res)=>{
      setValue('employee_number',res?.data?.employee_number)
    })
    callFetch('roles/all',"GET", []).then((res)=>{
      setRoles(res?.data);
    })
  },[0])



const onSubmit = (formData) => {
  setSaving(true);
  callFetch("employees", "POST", formData, setError).then((res) => {
      setSaving(false);
      if (!res.ok) return;
      setSubmitSuccess(true);
  });
};

return submitSuccess ? <Navigate to='/hr/employee' /> :
<form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
    <div className="row">
        <div className="col-9">
            <div className="card mb-4">
                <div className="card-header pb-0">
                    <h6>{t('Employee Create')}</h6>
                </div>
                <div className="card-body">
                        <div className="row g-3">
                            <div className="col-md-4">
                                <label>
                                    {t('Employee Number')} *
                                </label>
                                <input
                                    type="text"
                                    className="form-control mb-4"
                                    placeholder={t('eg. 123')}
                                    {...register("employee_number", {
                                        required: true,
                                    })}
                                    required
                                    readOnly
                                />
                                <div className="invalid-feedback">{errors.employee_number && errors.employee_number.message}</div>
                            </div>
                            <div className="col-md-4">
                                <label>
                                    {t('First Name')} *
                                </label>
                                <input
                                    type="text"
                                    className="form-control mb-4"
                                    placeholder={t('eg. Jhon')}
                                    {...register("first_name", {
                                        required: true,
                                    })}
                                    required
                                />
                                <div className="invalid-feedback">{errors.first_name && errors.first_name.message}</div>
                            </div>
                            <div className="col-md-4">
                                <label>
                                    {t('Surname')} *
                                </label>
                                <input
                                    type="text"
                                    className="form-control mb-4"
                                    placeholder={t('eg.Doe')}
                                    {...register("surname", {
                                        required: true,
                                    })}
                                    required
                                />
                                <div className="invalid-feedback">{errors.surname && errors.surname.message}</div>
                            </div>
                        </div>
                        <div className="row g-3">
                            <div className="col-md-6">
                                <label>
                                    {t('Password')} *
                                </label>
                                <input
                                    type="password"
                                    className="form-control mb-4"
                                    placeholder={t('*******')}
                                    {...register("password", {
                                        required: true,
                                    })}
                                    required
                                />
                                <div className="invalid-feedback">{errors.password && errors.password.message}</div>
                            </div>
                            <div className="col-md-6">
                                <label>
                                    {t('Email')} *
                                </label>
                                <input
                                    type="email"
                                    className="form-control mb-4"
                                    placeholder={t('eg. email@mail.com')}
                                    {...register("email", {
                                        required: true,
                                    })}
                                    required
                                />
                                <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                            </div>
                            
                        </div>
                        <div className="row g-3">
                            <div className="col-md-6">
                                <label>
                                    {t('Role')}
                                </label>
                                <select className="form-control mb-4" {...register("role",{required:true})} required>
                                    <option>---</option>
                                    {
                                    roles && roles.map((role) =>(
                                        <option key={role?.id} value={role?.id}>{role?.name}</option>
                                    ))
                                    }

                                </select>
                                <div className="invalid-feedback">{errors.company && errors.company.message}</div>
                            </div>
                            <div className="col-md-6">
                                <label>{t('Profile Picture')}</label>
                                <input type="file" className="form-control mb-4" {...register("photo")} />
                                <div className="invalid-feedback">{errors.photo && errors.photo.message}</div>
                            </div>
                        </div>
                        
                    
                        <div className="col-12 mb-4 text-end">
                            {!saving && (
                                <button type="submit" className="btn btn-primary">
                                    {t('Save')}
                                </button>
                            )}
                            {saving && (
                                <button type="submit" className="btn btn-disabled" disabled>
                                    {t('Saving ...')}
                                </button>
                            )}
                        </div>
                    
                </div>
            </div>
        
        </div>
        {/* <div className="col-3">
            <div className="card">
                <div className="card-body">
                    
                </div>
            </div>
        </div> */}
    </div>
</form>
}

export default EmployeeCreate;
