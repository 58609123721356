/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import callFetch from "helpers/callFetch";
import { useParams } from "react-router-dom";
import { Company } from "context/DashboardContext/DashboardContext";
import ProfileAvatar from "../ProfileAvatar";

function Header({imageUrl, data}) {
  const { dashboardlogo } = Company();
  const params = useParams()
  const [visible, setVisible] = useState(true);
  const [user,setUser] = useState({});
  const [datas, setDatas] = useState();
  const [roleDesigned, setRoleDesigned] = useState({})
  const handleSetVisible = () => setVisible(!visible);
  useEffect(() => {
    if(params?.id && window.location.href.split("/")[6] !== "create"){
      callFetch("customers/" + params.id + `/edit?company=${dashboardlogo}`, "GET", []).then((res) => {
        setDatas(res?.data)
        setUser(res?.userData)
      });
    }
}, [0]);

const imageRender = () => {
  if (imageUrl) {
    return imageUrl;
  } else if (params?.id && datas?.photo) {
    return datas?.photo !== null && process.env.REACT_APP_STORAGE_URL + datas?.photo;
  } else {
    return '/favicon.png';
  }
};
const returnImageUrl = imageRender();
  return (
    <Card id="profile">
      <SoftBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <ProfileAvatar
              src={(!imageUrl && !params?.id) ? '/favicon.png':returnImageUrl}
              width="4.625rem"
              height="4.625rem"
              alt="profile-image"
              shadow="shadow-sm"
            />
          </Grid> 
          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                {
                  (data?.company_name && !datas) && data?.company_name
                }

                {
                  (data?.company_name && datas) ? data?.company_name:datas && datas?.company_name
                }



                {
                  (!data?.company_name && !datas) && <>----</>
                }
                {/* {
                  !datas?.company_name && <>----</>
                } */}
              </SoftTypography>
              {/* <SoftTypography variant="button" color="text" fontWeight="medium">
                {
                  user?.designation ? user?.designation?.name:data?.role
                }
                /
                {
                  user?.department ? user?.department?.name:data?.department_id
                }
                
              </SoftTypography> */}
            </SoftBox>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={3} sx={{ mr: "auto" }}>
            <SoftBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <SoftTypography variant="caption" fontWeight="regular">
                Switch to {visible ? "invisible" : "visible"}
              </SoftTypography>
              <SoftBox mx={1}>
                <Switch checked={visible} onChange={handleSetVisible} />
              </SoftBox>
            </SoftBox>
          </Grid> */}
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default Header;
