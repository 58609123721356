import {AppBar, Card, Grid, Tab, Tabs } from '@mui/material'
import SoftAvatar from 'components/SoftAvatar'
import React, { useEffect, useState } from 'react'
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import Cube from 'examples/Icons/Cube';
import Document from 'examples/Icons/Document';
import tableData from "layouts/pages/users/reports/data/tableData";
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import callFetch from 'helpers/callFetch';
import { styled } from '@mui/material/styles';
import Settings from 'examples/Icons/Settings';
import { useTranslation } from 'react-i18next';
import curved0 from "assets/images/curved-images/curved0.jpg";
import SummaryCard from './components/SummaryCard';
import TaskCard from './components/TaskCard';
import NotesModal from './components/NotesModal';
import { Company } from 'context/DashboardContext/DashboardContext';
import ClientBaseQuotation from './ClientBaseDataTable/ClientBaseQuotation';
import ClientBseOrders from './ClientBaseDataTable/ClientBseOrders';
import ClientBaseInvoice from './ClientBaseDataTable/ClientBaseInvoice';
import ProfileAvatar from './components/ProfileAvatar';

const CustomerDetails = () => {
    const { dashboardlogo } = Company();
    const navigate = useNavigate();
    const params = useParams();
    const { t } = useTranslation();
    const { columns, rows } = tableData;
    const [editDelay, setEditDelay] = useState(0);
    const [tabsOrientation, setTabsOrientation] = useState("horizontal");
    const [tabValue, setTabValue] = useState(0);
    const [tabsOrientation2, setTabsOrientation2] = useState("horizontal");
    const [tabValue2, setTabValue2] = useState(0);
    const [customer,setCustomer] = useState({})
    const [customerOverview,setCustomerOverview] = useState({})
    const handleSetTabValue = (event, newValue) => setTabValue(newValue);
    const handleSetTabValue2 = (event, newValue) => setTabValue2(newValue);
    function TabPanel(props) {
      const { children, value, index, ...other } = props;
      
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            
             <div className='mt-4'>{children}</div>
            
          )}
        </div>
      );
    }

    useEffect(()=>{setEditDelay(editDelay + 1)},[0])
    useEffect(() => {
      
      if (editDelay == 1)
          callFetch("customers/" + params.id + `?company=${dashboardlogo}`, "GET", []).then((res) => {
            setCustomer(res.data);
            setCustomerOverview(res.data.overview);
          });
  }, [editDelay,params.id]);

  const SettingsEdit = () =>{
    navigate('/customer-managment/customer/' + params.id + '/edit')
  }

  const SmallAvatar = styled(SoftAvatar)(({ theme }) => ({
    width: 22,
    height: 22,
    backgroundColor:"#fff",
    color:"darkgray",
    cursor:"pointer"
  }));
  return (
    <>
    <SoftBox position="relative">
      <SoftBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="12.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${curved0})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            {/* <SoftAvatar
              src={customer?.customer?.photo ? process.env.REACT_APP_STORAGE_URL + customer?.customer?.photo : '/favicon.png'}
              alt="profile-image"
              variant="rounded"
              size="xl"
              shadow="sm"
            /> */}
            <ProfileAvatar
              src={customer?.customer?.photo ? process.env.REACT_APP_STORAGE_URL + customer?.customer?.photo : '/favicon.png'}
              width="4.625rem"
              height="4.625rem"
              alt="profile-image"
              shadow="shadow-sm"
            />
          </Grid>
          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                {customer?.customer?.company_name}
              </SoftTypography>
              <SoftTypography variant="button" color="text" fontWeight="medium">
                {customer?.customer?.client_number}
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} md={3} lg={3} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation2}
                value={tabValue2}
                onChange={handleSetTabValue2}
                sx={{ background: "transparent" }}
              >
                <Tab style={{ fontSize:"11px", fontWeight:"bold" }} className="" data-bs-toggle="modal" data-bs-target="#AddnotesModal" label="ADD NOTE" />
                <Tab style={{ fontSize:"11px", fontWeight:"bold" }} onClick={()=>SettingsEdit()} label="SETTINGS" />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Card>
    </SoftBox>

      <div className="col-md-2 mt-4" >
            <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
              <AppBar position="static">
                <Tabs
                  orientation={tabsOrientation}
                  value={tabValue}
                  onChange={handleSetTabValue}
                  sx={{ background: "transparent" }}
                  
                > 
                  <Tab style={{ fontSize:"11px", fontWeight:"bold" }} label={t("OVERVIEW")} />
                  <Tab style={{ fontSize:"11px", fontWeight:"bold" }} label={t("PROJECTS")} />
                </Tabs>
              </AppBar>
            </Grid>
            </div>      
                  <TabPanel value={tabValue} index={0}>
                  <div className='row' >
                  <div className='col-md-8 col-lg-8' >
                    <div className="card w-100" >
                          <div className="card-body pb-4">
                              <h6 className=' fw-normal' >Kunde</h6>
                              <div className='d-flex align-content-center' >
                                <SoftTypography className="w-15" variant="button" fontWeight="medium">{t('Name')}:</SoftTypography>
                                <SoftTypography variant="small" color="text" fontSize="0.9rem">{customer?.customer?.company_name}</SoftTypography>
                              </div>
                              <div className='d-flex align-content-center' >
                                <SoftTypography className="w-15" variant="button" fontWeight="medium">{t('Phone')}:</SoftTypography>
                                <SoftTypography variant="small" color="text" fontSize="0.9rem">{customer?.customer?.phone}</SoftTypography>
                              </div>
                               <div className='d-flex align-content-center' >
                                <SoftTypography className="w-15" variant="button" fontWeight="medium">{t('E-Mail')}:</SoftTypography>
                                <SoftTypography variant="small" color="text" fontSize="0.9rem">{customer?.customer?.company_email}</SoftTypography>
                              </div>
                              <div className='d-flex align-content-center'>
                                <SoftTypography className="w-15" variant="button" fontWeight="medium">{t('Address')}:</SoftTypography>
                                <SoftTypography variant="small" color="text" fontSize="0.9rem">{ customer?.customer?.street+", "+customer?.customer?.plz +", " +customer?.customer?.ort + ", "+customer?.customer?.country}</SoftTypography>
                              </div>
                          </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 mt-3 mt-sm-0 mt-md-0 mt-lg-0" >
                        <div className="row" >
                            <div className="col-md-6 mt-xs-3 mt-sm-3 mt-md-0 mt-lg-0" >
                              <SummaryCard
                                title={t("Active Users")}
                                icon="language_icon"
                                count={customer?.order}
                              />
                            </div>
                            <div className="col-md-6 mt-xs-3 mt-3 mt-sm-3 mt-md-0 mt-lg-0" >
                              <SummaryCard
                                title={t("Quotations")}
                                icon="description"
                                count={customer?.quotation}
                              />
                            </div>
                            <div className="col-md-6 mt-3" >
                              <SummaryCard
                                title={t("Orders")}
                                icon="shopping_cart"
                                count={customer?.order}
                              />
                            </div>
                            <div className="col-md-6 mt-3" >
                              <SummaryCard
                                title={t("Invoices")}
                                icon="wallet"
                                count={customer?.invoice}
                              />
                              
                            </div>
                        </div>
                    </div>
                    </div>
              </TabPanel>
    
              <TabPanel value={tabValue} index={1} >
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header pb-0 mb-0">
                        <h6>{t("Quotations")}</h6>
                      </div>
                      <div className="card-body px-0 pt-0 pb-2">
                        <ClientBaseQuotation/>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header pb-0 mb-0">
                        <h6>{t("Orders")}</h6>
                      </div>
                      <div className="card-body px-0 pt-0 pb-2">
                        <ClientBseOrders/>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header pb-0 mb-0">
                        <h6>{t("Invoices")}</h6>
                      </div>
                      <div className="card-body px-0 pt-0 pb-2">
                        <ClientBaseInvoice/>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>

            <NotesModal />
    </>
  )
}

export default CustomerDetails
