import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import dateFormat from "dateformat";
import flatpickr from "flatpickr";
import { NumericFormat } from 'react-number-format';
import SoftAlert from "components/SoftAlert";
import SoftSnackbar from "components/SoftSnackbar";
import { Menu, MenuItem } from '@mui/material'
const BundlePreisEdit = () => {
  const params = useParams()
  const { t } = useTranslation();
  const [currency, setCurrency] = useState('EUR');
  const [data, setData] = useState([]);
  const [emails, setEmails] = useState([{ name: '', email: '', currentEmail: '' }]);
  const [items, setItems] = useState([{ id: 0, title: '', quantity: 1, price: 0, currentPrice: 0, total: 0,artikel:'',product:"",description:"", inputTitle:"Product" }]);
  const [itemSummery, setItemSummery] = useState({ items: [], emails: [], subTotal: 0,amountPerchantage: 100, discountPercentage: 0, discount: 0, taxPercentage: 27, tax: 0, total: 0 });
  const [quotation, setQuotation] = useState(0);
  const [saving, setSaving] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [refresh2, setRefresh2] = useState(0);
  const [receivedError, setReceivedError] = useState(null);
  const [qq,setQqq] = useState([{quantity:0}])
  const [isShow, setIsShow] = useState(false)
  const [title, setTitle] = useState("Product")
  const {
      register,
      handleSubmit,
      setError,
      setValue,
      formState: { errors },
  } = useForm();
  
  useEffect(() => {
      var subTotal = 0;
      items.map((item) => {
          subTotal += item.total;
      });
      itemSummery.subTotal = subTotal;
      itemSummery.amount   = parseFloat((itemSummery.amountPerchantage / 100) * itemSummery.subTotal);
      // setSubtotal(subTotal);
      setValue('amountPerchantage', itemSummery.amountPerchantage);
      setValue('tax', itemSummery.taxPercentage);
      itemSummery.tax = parseFloat((itemSummery.amount * (itemSummery.taxPercentage / 100)).toFixed(2));
      itemSummery.discount = parseFloat((itemSummery.amount * (itemSummery.discountPercentage / 100)).toFixed(2));
      itemSummery.total = subTotal - itemSummery.discount + itemSummery.tax;
      itemSummery.items = items;
      setRefresh2(refresh2 + 1);
  }, [refresh]);

  useEffect(() => { }, [refresh2]);


  useEffect(() => {
    flatpickr(".flat-pickr");
    callFetch("bundle/create", "GET", []).then((res) => {
        setData(res?.data);
        setRefresh(refresh + 1)
    });
  }, [params?.id]);

  useEffect(() => {
      flatpickr(".flat-pickr");
      callFetch("bundle/"+params?.id+ "/edit", "GET", []).then((res) => {
          for (let [key, value] of Object.entries(res.data?.bundle)) {
            setValue(key, (value == null || value == 'null' ? "" : value ));
          }
          let product_details = JSON.parse(res.data?.bundle?.product_details);
          setItemSummery(product_details);
          setItems(product_details.items);
          setRefresh(refresh + 1)
      });
  }, []);

  function deleteProduct() {
      var titems = [];
      items.map((t) => {
          if (!t)
              return;
          titems.push(t);
      });
      setItems(titems);
      setRefresh(refresh + 1);
  }

  function deleteEmail() {
      var temails = [];
      emails.map((e) => {
          if (!e && e !== '')
              return;
          temails.push(e);
      });
      setEmails(temails);
      itemSummery.emails = temails;
      setItemSummery(itemSummery);
      setRefresh2(refresh2 + 1);
  }

  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const renderSuccessSB = (
      <SoftSnackbar
        color="success"
        icon="check"
        title="Success"
        content="Invoice successfully created"
        dateTime="Just Now"
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
      />
    );

  const onSubmit = (formData) => {
       setSaving(true);
      formData.product_details = JSON.stringify(itemSummery);
      callFetch("bundle/" + params?.id, "POST", formData, setError).then((res) => {
          if(receivedError == null){
              openSuccessSB();
          } 
          setSaving(false);
          if (!res.ok) return;
          setSubmitSuccess(true);
      });
  };


  const [menu, setMenu] = useState(null);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = (e) => {
      if(e.length > 0){
          setTitle((prevTitle)=>prevTitle + ' ' + title)
          setItems([...items, { id: 0, inputTitle:e, title: '', quantity: 1, price: 0, currentPrice: 0, total: 0 }]); setRefresh(refresh + 1);
      }
      
      setMenu(null)
  };
  const renderMenu = (
      <Menu
        anchorEl={menu}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={Boolean(menu)}
        onClose={closeMenu}
        keepMounted
      >
        <MenuItem onClick={()=>closeMenu('Product')}>{t('Product')}</MenuItem>
        <MenuItem onClick={()=>closeMenu('Zusatzkosten SP')}>{t('Zusatzkosten SP')}</MenuItem>
        <MenuItem onClick={()=>closeMenu('Kosten Int.')}>{t('Kosten Int.')}</MenuItem>
        <MenuItem onClick={()=>closeMenu('Kosten ADM')}>{t('Kosten ADM')}</MenuItem>
        {/* <Divider sx={{ margin: "0.5rem 0" }} />
          <MenuItem onClick={closeMenu}>
            <SoftTypography variant="button" color="error" fontWeight="regular">
              Remove Filter
            </SoftTypography>
          </MenuItem> */}
      </Menu>
    );



return submitSuccess ? <Navigate to={`/finance/bundle-preis`} /> :
  <div className="row">
      <div className="col-9">
          <div className="card mb-4">
              <div className="card-header pb-0">
                  <h6>{t('Rechung hinzufugen')}</h6>
              </div>
              <div className="card-body">
                  <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                    <input type="hidden" defaultValue="PUT" {...register("_method")} />
                      <div className="row g-3">
                          <div className="col-md-4">
                              <label>{t('Bundle-Preis Nr.')} *</label>
                               <input
                                  type="text"
                                  className="form-control"
                                  placeholder={t('eg: buldle-001')}
                                  {...register("bundle_nummer", {
                                      required: true,
                                  })}
                                  readOnly
                                  required
                              />
                              <div className="invalid-feedback">{errors.bundle_nummer && errors.bundle_nummer.message}</div>
                          </div>

                          <div className="col-md-6">
                                  <div className="form-group">
                                      <label>
                                          {t('Bundle')} *
                                      </label>
                                      <br />
                                      <input
                                          type="text"
                                          className="form-control"
                                          {...register("bundle", {
                                              required: true,
                                          })}
                                          onChange={(e) => {
                                              setCurrency(e.target.value)
                                          }}
                                          required
                                      />
                                      <div className="invalid-feedback">{errors.currency && errors.currency.message}</div>
                                  </div>
                          </div>

                          <div className="col-md-2">
                                  <div className="form-group">
                                      <label>
                                          {t('Currency')} *
                                      </label>
                                      <br />
                                      <select
                                          className="form-control"
                                          {...register("currency", {
                                              required: true,
                                          })}
                                          onChange={(e) => {
                                              setCurrency(e.target.value)
                                          }}
                                          required
                                      >
                                          <option value="EUR">EUR (€)</option>
                                          <option value="USD">USD ($)</option>
                                      </select>
                                      <div className="invalid-feedback">{errors.currency && errors.currency.message}</div>
                                  </div>
                          </div>

                      </div>

                      <div className="row mt-3">
                              <div className="col-md-12">
                                  <label>{t('Description')}</label>
                                  <textarea className="form-control" rows="3"
                                      defaultValue=""
                                      placeholder="eg. Description"
                                      {...register("description")}></textarea>
                              </div>
                          </div>
                      

                      <div className="mt-3">
                          {items && items.map((item, i) => (
                              <div key={i} className="">
                                  {i > 0 && <hr />}
                                  <div className="row mt-2">
                                      <div className="col-md-4">
                                          <div>
                                              <label>{t(`${item?.inputTitle}`)}</label>
                                              {
                                                  item.inputTitle === "Product" ? <select type="text" className="form-control" placeholder={t('Select Product')} value={items[i].artikel} onChange={(e) => {
                                                      items[i].id = e.target.value;
                                                      items[i].artikel = e.target.value;
                                                      setItems(items);
                                                      setRefresh(refresh + 1);
                                                  }}>
                                                      <option value="">--</option>
                                                      {data?.product && data?.product?.map((product, index) => (
                                                          <option key={index} value={product.module_identity}>{product.module_identity}</option>
                                                      ))}
                                                      {data?.speicher && data?.speicher?.map((speiche, index) => (
                                                          <option key={index} value={speiche.speicher_identity}>{speiche.speicher_identity}</option>
                                                      ))}
                                                      {data?.wechselrichter && data?.wechselrichter?.map((wechselrichte, index) => (
                                                          <option key={index} value={wechselrichte.identity}>{wechselrichte.identity}</option>
                                                      ))}
                                                  </select>:<select type="text" className="form-control" placeholder={t('Select Product')} defaultValue={items[i].artikel} onChange={(e) => {
                                                      items[i].id = e.target.value;
                                                      items[i].artikel = e.target.value;
                                                      setItems(items);
                                                      setRefresh(refresh + 1);
                                                  }}>
                                                      <option value="">--</option>
                                                      {data?.artikel && data?.artikel?.map((artike, index) => (
                                                          <option key={index}  value={artike?.artikel_id}>{artike?.artikel_id}</option>
                                                      ))}
                                                  </select>
                                              }
                                          </div>
                                      </div>
                                      <div className="col-md-4">
                                          <div>
                                              <label>{t('Description')}</label>
                                              <input type="text" className="form-control" placeholder={t('Description')} defaultValue={items[i].description} onChange={(e) => {
                                                  items[i].id = e.target.value;
                                                  items[i].description = e.target.value;
                                                  setItems(items);
                                                  setRefresh(refresh + 1);
                                              }}/>
                                          </div>
                                      </div>
                                      <div className="col-md-2">
                                          <div>
                                              <label>{t('Qty/Hrs')}</label>
                                              <input type="number"  className="form-control" placeholder="eg. 0.00" value={items[i].quantity} onChange={(e) => {
                                                  let value = 0;
                                                  if (e.target.value.length && !isNaN(e.target.value))
                                                      value = e.target.value;
                                                      if(value > 0){
                                                          setQqq(parseFloat(value));
                                                      }
                                                  items[i].quantity = parseFloat(value);
                                                  items[i].total = items[i].currentPrice * items[i].quantity;
                                                  setItems(items);
                                                  setRefresh(refresh + 1);
                                              }} />
                                              
                                          </div>
                                      </div>
                                      
                                      <div className="col-md-2">
                                          <div>
                                              <label>{t('Amount')}</label>
                                              <input type="number" className="form-control d-inline" style={{ width: '84%' }} placeholder="eg. 0.00" value={item.currentPrice ? item.currentPrice : ''} onChange={(e) => {
                                                let value = 0;
                                                if (e.target.value.length && !isNaN(e.target.value))
                                                    value = e.target.value;
                                                items[i].currentPrice = parseFloat(value);
                                                items[i].total = items[i].currentPrice * items[i].quantity;
                                                setItems(items);
                                                setRefresh(refresh + 1);
                                          }} />
                                               &nbsp;
                                              <i className="fa-solid fa-circle-xmark text-danger" data-key={i} onClick={() => { delete items[i]; deleteProduct(); }}></i>
                                          </div>
                                      </div>

                                  </div>
                              </div>
                          ))}

                          <div className="row mt-3 ">
                              <div className="col-md-12">
                                  <p className="btn btn-dark" style={{ cursor: 'pointer' }} onClick={openMenu}><i className="fa-solid fa-circle-plus"></i>&nbsp;{t('Add Item')}</p>
                                  {/* <p className="btn btn-dark" style={{ cursor: 'pointer' }} onClick={() => { setItems([...items, { id: 0, title: '', quantity: 0, price: 0, currentPrice: 0, total: 0 }]); setRefresh(refresh + 1); }}><i className="fa-solid fa-circle-plus"></i>&nbsp;{t('Add Item')}</p> */}
                                  {renderMenu}
                              </div>
                          </div>

                          <hr />

                          <div className="row">
                              <div className="col-md-6 offset-md-6 border">
                                  <div className="row">
                                      <div className="col-md-7 border-end">
                                          <label className="d-block text-end">{t('Sub Total')}</label>
                                          {/* <select {...register('amountPerchantage')} onChange={(e) =>{ itemSummery.amountPerchantage = parseFloat(e.target.value); setItemSummery(itemSummery);
                                              setRefresh(refresh + 1) }} className="form-control mb-2" style={{width: '70%', position: 'relative', left: '68px'}}>
                                              <option value="0">--</option>
                                              <option value="100">100%</option>
                                              <option value="70">70%</option>
                                              <option value="30">30%</option>
                                          </select> */}
                                      </div>
                                      <div className="col-md-5">
                                          <p className="text-end">
                                              <NumericFormat 
                                                  value={itemSummery.subTotal}
                                                  displayType="text" 
                                                  thousandSeparator={"."} 
                                                  decimalSeparator=","
                                                  decimalScale={2}
                                                  fixedDecimalScale
                                                  suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                              />
                                              </p>
                                      </div>
                                  </div>
                                  <div className="row border-top d-none">
                                      <div className="col-md-3 border-end">
                                          <label className="d-block text-end">{t('Discount')}</label>
                                      </div>
                                      <div className="col-md-3 border-end">
                                          <input type="number" className="form-control" onChange={(e) => {
                                              let value = 0;
                                              if (e.target.value.length && !isNaN(e.target.value))
                                                  value = e.target.value;
                                              itemSummery.discountPercentage = parseFloat(value);
                                              setItemSummery(itemSummery);
                                              setRefresh(refresh + 1);
                                          }} />
                                      </div>
                                      <div className="col-md-1 border-end">
                                          <p className="text-end">%</p>
                                      </div>
                                      <div className="col-md-5">
                                          <p className="text-end">
                                              <NumericFormat 
                                                  value={itemSummery.subTotal}
                                                  displayType="text" 
                                                  thousandSeparator={"."} 
                                                  decimalSeparator=","
                                                  decimalScale={2}
                                                  fixedDecimalScale
                                                  suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                              />
                                          </p>
                                      </div>
                                  </div>
                                  <div className="row border-top">
                                      <div className="col-md-3 border-end">
                                          <label className="d-block text-end">{t('Tax')}</label>
                                      </div>
                                      <div className="col-md-4 border-end">
                                         <select {...register('tax')} className="form-control" onChange={(e) => {
                                              let value = 27;
                                              if (e.target.value.length && !isNaN(e.target.value))
                                                  value = e.target.value;
                                              itemSummery.taxPercentage = parseFloat(value);
                                              setItemSummery(itemSummery);
                                              setRefresh(refresh + 1);
                                          }}>
                                              <option value="27">27%</option>
                                         </select>
                                      </div>
                                      <div className="col-md-5">
                                          <p className="text-end">
                                              <NumericFormat 
                                                  value={itemSummery.tax}
                                                  displayType="text" 
                                                  thousandSeparator={"."} 
                                                  decimalSeparator=","
                                                  decimalScale={2}
                                                  fixedDecimalScale
                                                  suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                              />
                                          </p>
                                      </div>
                                  </div>
                                  <div className="row bg-gray-200">
                                      <div className="col-md-7">
                                          <label className="d-block text-end">{t('Total')}</label>
                                      </div>
                                      <div className="col-md-5">
                                          <p className="text-end">
                                              <NumericFormat 
                                                  value={itemSummery.total}
                                                  displayType="text" 
                                                  thousandSeparator={"."} 
                                                  decimalSeparator=","
                                                  decimalScale={2}
                                                  fixedDecimalScale
                                                  suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                              />
                                          </p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      
                     

                      <div className="row mt-3">
                          <div className="col-md-5">{receivedError && <SoftAlert color="error" dismissible> {receivedError} </SoftAlert> }</div>
                          <div className="col-md-12 mt-3">
                              {!saving && (
                                  <button type="submit" className="btn btn-primary">
                                      {t('Save')}
                                  </button>
                              )}
                              {saving && (
                                  <button type="submit" className="btn btn-disabled" disabled>
                                      {t('Saving ...')}
                                  </button>
                              )}
                          </div>
                      </div>
                  </form>
              </div>
          </div> 
      </div>
      {renderSuccessSB}
  </div>;
}

export default BundlePreisEdit