import { ToastContainer } from "react-toastify";


const { createContext, useState, useContext } = require("react");


const AlertContext = createContext(null)


export function AlertContextProvider({children}){
    const [color,setColor] = useState("success");
    const [icon,setIcon] = useState("check");
    const [title,setTitle] = useState("Success");
    const [message,setMessage] = useState("")
    const [isShow, setIsShow] = useState(false);

    return <>
        <AlertContext.Provider value={{color,setColor,icon,setIcon,title,setTitle,message,setMessage,isShow, setIsShow}}>
            {children}
            <ToastContainer/>
        </AlertContext.Provider>
    </>
}

export function useAlert(){
    const context = useContext(AlertContext)
    return context;
}