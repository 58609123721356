import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "helpers/callFetch";
import SoftSnackbar from "components/SoftSnackbar";

function MonthlySalary({ title,employee,refreshData }) {
    const params = useParams();
    const { t } = useTranslation();
    const [designations, setDesignations] = useState([]);
    const [roles, setRoles] = useState([]);
    const [responsible,setResponsible] = useState([{id:0, name:'',surname:'',email:'', phone:'',position:''}]);
    const [departments, setDepartments] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isModalVisible, setModalVisible] = useState(false);
    const [files,setFile] = useState();
    const [image, setImage] = useState();
    const [provision, setProvision] = useState('')
    const [receivedError, setReceivedError] = useState(null);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();




  const handleBlur = () => {
    if (provision !== '' && !provision.endsWith('%')) {
      setValue(provision + '%');
    }
  };
    
  
   
    

    useEffect(() => {
        if(employee?.responsible_persons){
            const response_persons = JSON.parse(employee?.responsible_persons);
            if(employee?.responsible_persons !== null){
                setResponsible(response_persons);
            }
        }
    }, [employee]);

    function deletePerson(index) {
        const updatedResponsible = responsible.filter((_, i) => i !== index);
        setResponsible(updatedResponsible);
        //setRefresh(refresh + 1);
    }

    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
          color="success"
          icon="check"
          title="Success"
          content="Responsibler Person added successfully"
          dateTime="Just Now"
          open={successSB}
          onClose={closeSuccessSB}
          close={closeSuccessSB}
          bgWhite
        />
      );


    const onSubmit = (formData) => {
        formData.responsible_persons = JSON.stringify(responsible)
        setSaving(true);
        callFetch(params?.id ? `customers/${params?.id}`:"customers", "POST", formData, setError).then((res) => {
            setSaving(false);
            if(receivedError == null && res.message === 'success' && params?.id){
                openSuccessSB();
            }
            refreshData();
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="card mb-4">
                    <div className="card-header pb-0">
                        <h6>{t(`${title}`)}</h6>
                    </div>
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            {params?.id && <input type="hidden" defaultValue="PUT" {...register("_method")} />}
                            {
                                responsible?.map((item,index) =>(
                                    <div className="card mb-4 shadow-sm">
                                        <div className="card-header p-0 m-0">
                                            <i data-key={index} onClick={() => deletePerson(index)} className="fa-solid fa-circle-xmark text-danger float-end m-2 cursor-pointer"></i>
                                        </div>
                                        <div className="card-body pt-0">
                                            <div className="row g-3">
                                            <div className="col-md-6">
                                                <label>
                                                    {t('Name')} *
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control mb-4"
                                                        placeholder={t('name')}
                                                        defaultValue={responsible[index].name}
                                                        onChange={(e)=>{
                                                            responsible[index].id = index + 1;
                                                            responsible[index].name = e.target.value;
                                                            setResponsible(responsible);
                                                        }}
                                                        required
                                                    />

                                                </div>
                                                <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                            </div>
                                            <div className="col-md-6">
                                                <label>
                                                    {t('Surname')} *
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control mb-4"
                                                        placeholder={t('surname')}
                                                        defaultValue={responsible[index].surname}
                                                        onChange={(e)=>{
                                                            responsible[index].surname = e.target.value;
                                                            setResponsible(responsible);
                                                        }}
                                                        required
                                                    />
                                                </div>
                                                <div className="invalid-feedback">{errors.surname && errors.surname.message}</div>
                                            </div>
                                            </div>

                                            <div className="row g-3">
                                                <div className="col-md-6">
                                                    <label>
                                                        {t('E-mail')} *
                                                    </label>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder={t('email')}
                                                            defaultValue={responsible[index].email}
                                                            onChange={(e)=>{
                                                                responsible[index].email = e.target.value;
                                                                setResponsible(responsible);
                                                            }}
                                                            required
                                                        />

                                                    </div>
                                                    <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>
                                                        {t('Phone')} *
                                                    </label>
                                                    <div className="input-group">
                                                        <input
                                                            type="number"
                                                            className="form-control mb-4"
                                                            placeholder={t('phone')}
                                                            defaultValue={responsible[index].phone}
                                                            onChange={(e)=>{
                                                                responsible[index].phone = e.target.value;
                                                                setResponsible(responsible);
                                                            }}
                                                            required
                                                            
                                                        />
                                                    </div>
                                                    <div className="invalid-feedback">{errors.phone && errors.phone.message}</div>
                                                </div>
                                            </div>

                                            <div className="row g-3">
                                                <div className="col-md-6">
                                                    <label>
                                                        {t('Position')} *
                                                    </label>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder={t('position')}
                                                            defaultValue={responsible[index].position}
                                                            onChange={(e)=>{
                                                                responsible[index].position = e.target.value;
                                                                setResponsible(responsible);
                                                            }}
                                                            required
                                                        />

                                                    </div>
                                                    <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <p className="btn btn-dark" style={{ cursor: 'pointer' }} onClick={() => { setResponsible([...responsible, {id:0, name:'',surname:'',email:'', phone:'',position:'' }]); setRefresh(refresh + 1); }}><i className="fa-solid fa-circle-plus"></i>&nbsp;{t('Add Item')}</p>
                                    </div>
                                </div>

                            
                            <div className="col-12 mb-4 text-end mt-5">
                                {!saving && (
                                    <button type="submit" className="btn btn-primary">
                                        {t('Save')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled" disabled>
                                        {t('Saving ...')}
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>

            </div>
            {renderSuccessSB}
        </div>
    )
}

export default MonthlySalary;
